<template>
  <div class="flex-col page">
    <div class="flex-col section">
      <div class="flex-col section_1">
        <vheader/>
        <div class="flex-col section_2">
          <span class="text">自媒体中心</span>
          <img style="width: 95%;display: block;margin: 13rem auto 0;" src="@/assets/images/zmt.png" alt="">
          <!-- <div class="flex-col group_2">
            <div class="flex-row">
              <div class="justify-center equal-division-item">
                <img
                  src="@/assets/images/code/1.png"
                  class="image_6"
                />
                <div class="flex-col group_3">
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525123454750530392.png"
                    class="image_7"
                  />
                  <span class="text_1">了解光阳更多信息</span>
                  <div class="group_4">
                    <span class="text_2">@</span>
                    <span class="text_3">光阳电动</span>
                    <span class="text_4">官方微信公众号</span>
                  </div>
                </div>
              </div>
              <div class="horiz-divider section_3"></div>
              <div class="justify-center equal-division-item_1">
                <img
                  src="@/assets/images/code/2.png"
                  class="image_8"
                />
                <div class="flex-col group_5">
                  <img
                    src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525123661332157489.png"
                    class="image_9"
                  />
                  <span class="text_5">看光阳，享热爱</span>
                  <div class="group_6">
                    <span class="text_6">@</span>
                    <span class="text_7">光阳电动</span>
                    <span class="text_8">官方小红书</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-divider"></div>
            <div class="flex-row equal-division_1">
              <div class="justify-center equal-division-item_2">
                <img
                 src="@/assets/images/code/3.png"
                  class="image_10"
                />
                <div class="flex-col group_7">
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525123454850456390.png"
                    class="image_11"
                  />
                  <span class="text_9">发现光阳新鲜事</span>
                  <div class="group_8">
                    <span class="text_10">@</span>
                    <span class="text_11">光阳电动</span>
                    <span class="text_12">官方微博</span>
                  </div>
                </div>
              </div>
              <div class="horiz-divider section_4"></div>
              <div class="justify-center equal-division-item_3">
                <img
                 src="@/assets/images/code/4.png"
                  class="image_10"
                />
                <div class="flex-col group_9">
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525123452715686634.png"
                    class="image_13"
                  />
                  <span class="text_13">记录光阳动态</span>
                  <div class="group_10">
                    <span class="text_14">搜索@</span>
                    <span class="text_15">光阳电动</span>
                    <span class="text_16">官方抖音</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div style="margin-top: 35.5rem;">
          <vfooter></vfooter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
  };
</script>

<style scoped lang="css">
  .image_1 {
    width: 38px;
    height: 26px;
  }
  .center-text-wrapper {
    width: 121px;
  }
  .text_17 {
    opacity: 0.7;
  }
  .horiz-divider {
    background-color: rgb(255, 255, 255);
    width: 1px;
    height: 100px;
  }
  .page {
    width: 100%;
    overflow-y: auto;
    height: 100%;
  }
  .section {
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16524901229737534885.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .section_1 {

    background-image: url(../assets/images/index/3b.gif);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .section_2 {
    margin: 196px 36px 0 ;
    padding: 88px 7px 105px;
    border-radius: 27px;
    backdrop-filter: blur(200px);
    box-shadow: 0px 2.66px 2.66px rgba(0, 0, 0, 0.25);
    background-image: url('../assets/images/code/bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .section_5 {
    margin-top: 353px;
    padding: 6px 77px 5px 110px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
  }
  .image {
    width: 167px;
    height: 28px;
  }
  .image_2 {
    margin-left: 14px;
  }
  .image_3 {
    margin-left: 8px;
    width: 38px;
    height: 25px;
  }
  .image_4 {
    margin-left: 8px;
  }
  .image_5 {
    margin-left: 5px;
  }
  .text {
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-weight: 300;
    line-height: 37px;
    letter-spacing: 16px;
    white-space: nowrap;
  }
  .group_2 {
    margin-top: 73px;
  }
  .text-wrapper {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 600;
    line-height: 15px;
    white-space: nowrap;
  }
  .view {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 600;
    line-height: 15px;
    white-space: nowrap;
  }
  .view_1 {
    color: rgb(255, 255, 255);
    font-size: 30px;
    font-weight: 600;
    line-height: 19px;
    white-space: nowrap;
  }
  .list-divider {
    margin-left: 28px;
    margin-right: 19px;
    background-color: rgb(255, 255, 255);
    height: 2px;
  }
  .equal-division_1 {
    padding-top: 4px;
  }
  .equal-division-item {
    padding: 25px 18px 33px 32px;
    flex: 1 1 327px;
  }
  .section_3 {
    margin: 25px 0 25px;
  }
  .equal-division-item_1 {
    padding: 25px 21px 31px 49px;
    flex: 1 1 327px;
  }
  .equal-division-item_2 {
    padding: 28px 30px 29px;
    flex: 1 1 327px;
  }
  .section_4 {
    margin: 21px 0 28px;
  }
  .equal-division-item_3 {
    padding: 23px 18px 24px 42px;
    flex: 1 1 327px;
  }
  .image_6 {
    width: 135px;
    height: 135px;
  }
  .group_3 {
    margin-left: 21px;
  }
  .image_8 {
    margin-bottom: 2px;
    width: 135px;
    height: 135px;
  }
  .group_5 {
    margin-left: 21px;
  }
  .image_10 {
    width: 135px;
    height: 135px;
  }
  .group_7 {
    margin-left: 23px;
  }
  .image-wrapper {
    padding: 30px 0 30px;
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525123451499526173.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 103px;
    height: 103px;
  }
  .group_9 {
    margin: 10px 0 5px 16px;
  }
  .image_7 {
    width: 39px;
    height: 31px;
  }
  .text_1 {
    margin-left: 2px;
    margin-top: 17px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 600;
    line-height: 19px;
    white-space: nowrap;
  }
  .group_4 {
    margin-top: 10px;
    line-height: 12px;
    white-space: nowrap;
  }
  .image_9 {
    width: 37px;
    height: 37px;
  }
  .text_5 {
    margin-top: 14px;
    color: rgb(255, 255, 255);
    font-size: 30px;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
  }
  .group_6 {
    margin-top: 9px;
    line-height: 12px;
    white-space: nowrap;
  }
  .image_11 {
    width: 43px;
    height: 36px;
  }
  .text_9 {
    margin-top: 13px;
    color: rgb(255, 255, 255);
    font-size: 30px;
    font-weight: 600;
    line-height: 19px;
    white-space: nowrap;
  }
  .group_8 {
    margin-top: 10px;
    line-height: 12px;
    white-space: nowrap;
  }
  .image_12 {
    border-radius: 50%;
    width: 42px;
    height: 42px;
  }
  .image_13 {
    width: 28px;
    height: 33px;
  }
  .text_13 {
    margin-top: 13px;
    color: rgb(255, 255, 255);
    font-size: 30px;
    font-weight: 600;
    line-height: 19px;
    white-space: nowrap;
  }
  .group_10 {
    margin-top: 10px;
    line-height: 12px;
    white-space: nowrap;
  }
  .text_2 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    line-height: 10px;
  }
  .text_3 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }
  .text_4 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
  }
  .text_6 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    line-height: 10px;
  }
  .text_7 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }
  .text_8 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
  }
  .text_10 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    line-height: 10px;
  }
  .text_11 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }
  .text_12 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
  }
  .text_14 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
  }
  .text_15 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }
  .text_16 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
  }
</style>
