<template>
  <div class="flex-col component">
    <div v-if="ifreg" style="padding: 0 1rem">
      <div
        id="code"
        style="padding: 20px; border: 1px solid gray"
        ref="qrCodeUrl"
      ></div>
      <p style="text-align: center; font-size: 15px">使用微信扫一扫<br /></p>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { api } from "@/config";
export default {
  props: ["onOk", "datas"],
  data() {
    return {
      ifreg: true,
      times: "",
    };
  },
  // watch: {
  //   datas() {
  //     this.$nextTick(() => {
  //       this.creatQrCode();
  //     });
  //   },
  // },
  mounted() {
    this.$nextTick(() => {
      this.creatQrCode();
    });
  },
  methods: {
    creatQrCode() {
      console.log(this.datas);
      var arr=[];
      this.datas.forEach(t=>{
        arr.push(api+t);
        })
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text:
          "http://zyd.kymco.life/#/down?url="+arr.join(","), // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.Q,
      });
    },
  },
};
</script>

<style scoped lang="css">
.component {
  background: #fff;
  padding: 20px;
}
.code {
  width: 200px;
  height: 200px;
}
</style>
