<template>
  <div @touchstart="start">
    <audio v-if="audio" id="audio" loop preload autoplay src="@/assets/images/demo/1.mp3"></audio>
    <div class="fullscreen fullpage-container" id="holder">
      <div class="fullscreen-wp" v-fullscreen="opts" ref="fullscreen">
        <div class="page-1 page">
          <div class="items-center justify-center bg" style="background-size:cover;background-position: center center;">
            <img class="imgss" v-animate="{ value: 'fadeIn' }" src="@/assets/images/cynb/new/001.png" alt="">
          </div>

        </div>
        <div class="page-2 page">
          <div class="items-center justify-center bgs"
            style="background-size:cover;background-position: center center;height: 100vh;">
            <img class="imgss" v-animate="{ value: 'fadeIn' }" src="@/assets/images/cynb/new/002.png" alt="">
          </div>
        </div>
        <div class="page-3 page">
          <div class="items-center justify-center bg3"
            style="background-size:cover;background-position: center center;height: 100vh;">
            <img class="imgss" v-animate="{ value: 'fadeIn' }" src="@/assets/images/cynb/new/003.png" alt="">
          </div>

        </div>

        <div class="page-3 page">
          <div class="items-center justify-center bg4"
            style="background-size:cover;background-position: center center;height: 100vh;">
            <img class="imgss" v-animate="{ value: 'fadeIn' }" src="@/assets/images/cynb/new/004.png" alt="">
          </div>

        </div>
        <div class="page-3 page">
          <div class="items-center justify-center bg4"
            style="background-size:cover;background-position: center center;height: 100vh;">
            <img class="imgss" v-animate="{ value: 'fadeIn' }" src="@/assets/images/cynb/new/005.png" alt="">
            <div v-animate="{ value: 'fadeIn' }"
              style="border-radius: 2rem;overflow: hidden;position: absolute;width: 65%;top: 50%;transform: translateY(-50%);"
              class="newwidth">

              <img style="    width: 100%;height: 73rem;object-fit: cover;position: relative;top: -1rem;" class="block "
                src="@/assets/images/cynb/10/1.gif" alt=""></img>

            </div>
          </div>


        </div>
        <div class="page-3 page">
          <div class="items-center justify-center bg4"
            style="background-size:cover;background-position: center center;height: 100vh;">
            <img class="imgss" v-animate="{ value: 'fadeIn' }" src="@/assets/images/cynb/new/005.png" alt="">
            <div v-animate="{ value: 'fadeIn' }"
              style="border-radius: 2rem;overflow: hidden;position: absolute;width: 65%;top: 50%;transform: translateY(-50%);"
              class="newwidth">

              <img style="    width: 100%;height: 73rem;object-fit: cover;position: relative;top: -1rem;" class="block "
                src="@/assets/images/cynb/10/3.gif" alt=""></img>

            </div>
          </div>


        </div>
        
        <div class="page-3 page">
          <div class="items-center justify-center bg4"
            style="background-size:cover;background-position: center center;height: 100vh;">
            <img class="imgss" v-animate="{ value: 'fadeIn' }" src="@/assets/images/cynb/new/006.png" alt="">
          </div>

        </div>
        <div class="page-3 page">
          <div class="items-center justify-center bg4"
            style="background-size:cover;background-position: center center;height: 100vh;">

            <div style="padding: 0;position: relative;">
              <img class="imgss" v-animate="{ value: 'fadeIn' }" src="@/assets/images/cynb/new/007.png"
                alt=""></img>
              <a style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 999999;"
                href="https://apis.map.qq.com/uri/v1/geocoder?coord=40.67123,109.905411&amp;referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77"
                target="_blank">
              </a>

              <div v-animate="{ value: 'fadeIn' }" style="position: absolute ;  width: 56rem;
    height: 45rem;
    top: 72rem;
    left: 0;
    right: 0;
    margin: auto;" id="container"></div>
            </div>
          </div>

        </div>

        
        <div class="page-12 page">
          <img style="width: 100vw;height:100vh;" src="@/assets/images/cynb/12/bg.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// 获取地图scheme， 经纬度坐标类型要求为gcj02不然位置会有偏差from 从哪里
const getMapScheme = (from, to, mapType = "gaode") => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端

  const andriodBaidu = (to) => {
    return `bdapp://map/direction?destination=name:${to.name}|latlng:${to.latitude},${to.longitude}&coord_type=gcj02&mode=driving&src=andr.jianghu.jianhao`
  }

  const iOSBaidu = (to) => {
    return `baidumap://map/direction?destination=name:${to.name}|latlng:${to.latitude},${to.longitude}&coord_type=gcj02&mode=driving&src=ios.jianghu.jianhao`
  }

  const andriodGaode = (to) => {
    return `amapuri://route/plan/?sourceApplication=mhc&dlat=${to.latitude}&dlon=${to.longitude}&dname=${to.name}&dev=0&t=0`
  }

  const iOSGaode = (to) => {
    return `iosamap://path?sourceApplication=mhc&dlat=${to.latitude}&dlon=${to.longitude}&dname=${to.name}&dev=0&t=0`
  }
  if (mapType == "baidu") {
    if (isAndroid) {
      return andriodBaidu(to)
    } else {
      return iOSBaidu(to)
    }
  } else if (mapType == "gaode") {
    if (isAndroid) {
      return andriodGaode(to)
    } else {
      return iOSGaode(to)
    }
  }
}

export default {


  data() {
    return {
      audio: false,
      baiduUrl: "",
      opts: {
        start: 0,
        loop: true,
        direction: "v",
        duration: 500,
        preventWechat: true,
        beforeChange: (prev, next) => { },
        afterChange: (prev, next) => { },
      },
    };
  },
  mounted() {
    this.$nextTick(() => { 
      var map = new BMapGL.Map('container'); // 创建Map实例
map.centerAndZoom('包头市国药一机医院口腔医院', 13); // 初始化地图,设置中心点坐标和地图级别
map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
var point = new BMapGL.Point(109.905411,40.67123);
map.setMapStyleV2({     
  styleId: '639cec776c30ac5d12c2444948eed584' 
});
var marker = new BMapGL.Marker(point);
map.addOverlay(marker);
// 创建信息窗口
var opts = {
    width: 200,
    height: 100,
    title: '包头市国药一机医院口腔医院'
};
var infoWindow = new BMapGL.InfoWindow('包头市国药一机医院口腔医院', opts);
// 点标记添加点击事件
marker.addEventListener('click', function () {
    map.openInfoWindow(infoWindow, point); // 开启信息窗口
});

    });

  },
  async created() {
    let data = await this.service.get("/jssdk.php?url=" + window.location.href);

    const latitude = 109.905411; 
    const longitude = 40.67123;
    const from = {}  // 出发地，不传则默认当前位置
    const to = { name: "包头市国药一机医院口腔医院", longitude, latitude }; // address：目的地
    const gaodeUrl = getMapScheme(from, to, "gaode")
    const baiduUrl = getMapScheme(from, to, "baidu")

    this.baiduUrl = baiduUrl;
    this.initwx(data);
  },
  methods: {
    start() {
      this.audio = true;
    },

    initwx(data) {

      var url = ""; //分享的文章地址
      var appId = data.appId;
      var timestamp = data.timestamp;
      var nonceStr = data.nonceStr;
      var signature = data.signature;
      var debug = false;
      var link = data.url;
      console.log(timestamp, nonceStr, signature, link)
      wx.config({
        debug: debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: [
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",

        ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      // var { share_title, share_desc, share_img } = data;
      wx.ready(function () {
        wx.onMenuShareTimeline({
          title: "创英·研学堂 | 全口种植培训班", // 分享标题
          desc: "内蒙古站-7月25日", // 分享描述
          link: link, // 分享链接
          imgUrl: "http://zyd.kymco.life/cy.png", // 分享图标
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });

        wx.onMenuShareQQ({
          title: "创英·研学堂 | 全口种植培训班", // 分享标题
          desc: "内蒙古站-7月25日", // 分享描述
          link: link, // 分享链接
          imgUrl: "http://zyd.kymco.life/cy.png", // 分享图标
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }

        })

        wx.onMenuShareAppMessage({
          title: "创英·研学堂 | 全口种植培训班", // 分享标题
          desc: "内蒙古站-7月25日", // 分享描述
          link: link, // 分享链接
          imgUrl: "http://zyd.kymco.life/cy.png", // 分享图标
          type: "", // 分享类型,music、video或link，不填默认为link
          dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });
      });
    },
  }
};
</script>
<style>

.imgss{
  width: 100%;
}
.wxbottom {
  position: absolute;
  bottom: 32px;
  left: 76px;
}

.bottoms {
  position: absolute;
  bottom: 93px;
  width: 461px;
  left: 26px;
}

.tips {
  position: absolute;
  width: 300px;
  left: 0;
  top: 130px;
}

.wxjs {
  position: absolute;
  top: 202px;
  right: -100px;
}

html {
  font-size: 1.33333vw !important;
}
</style>

<style scoped lang="scss">
.newwidth {
  width: 85%;
  margin: 0 auto;
}

.newbottom {
  position: absolute;
  bottom: 32px;
  left: 4rem;
  right: 4rem;
}

.h30 {
  height: 25rem;
}

.h10 {
  height: 10rem;
}

.h27 {
  height: 27rem;
}

.h80 {
  height: 50rem;
}

.bicon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: none;
}

@mixin bgleft {
  background-size: 101% 101%;
  background-position: -8px -8px;

}

.bg {
  height: 100vh;
  background: url('../../assets/images/cynb/1/bg.png') no-repeat;

  padding: 0 40px;
  @include bgleft;
}

.bgs {
  background: url('../../assets/images/cynb/2/bg.png') no-repeat;
  @include bgleft;
}

.bg3 {
  background: url('../../assets/images/cynb/3/bg.png') no-repeat;
  @include bgleft;
}

.bg4 {
  background: url('../../assets/images/cynb/4/bg.png') no-repeat;
  @include bgleft;
}

.bg5 {
  background: url('../../assets/images/cynb/5/bg.png') no-repeat;
  @include bgleft;
}

.bg6 {
  background: url('../../assets/images/cynb/6/bg.png') no-repeat;
  @include bgleft;
}

.bg7 {
  background: url('../../assets/images/cynb/7/bg.png') no-repeat;
  @include bgleft;
}

.bg8 {
  background: url('../../assets/images/cynb/8/bg.png') no-repeat;
  @include bgleft;
}

.bg9 {
  background: url('../../assets/images/cynb/9/bg.png') no-repeat;
  @include bgleft;
}

.bg10 {
  background: url('../../assets/images/cynb/10/bg.png') no-repeat;
  @include bgleft;
}

.bg12 {
  background: url('../../assets/images/cynb/12/bg.png') no-repeat;
  @include bgleft;
}

.fullpage-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .page {
    width: 100%;
    height: 100%;
  }
}

.page {
  position: relative;
}

@keyframes steam {

  0%,
  100% {

    transform: skewX(0deg);

    opacity: 1;

  }

  25% {
    transform: skewX(10deg);
    opacity: .8;
  }

  75% {
    transform: skewX(-10deg);
    opacity: .8;
  }

}

.steam {

  animation: steam 1000ms infinite;

}

.block {
  display: block;

}
</style>
