<template>
  <div class="flex-col page index">
    <div style="min-height: 100vh">
      <vheader class="group_1" />
      <div class="wraps">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div v-for="t in list" class="swiper-slide">
              <img :src="t" alt="" />
            </div>
          </div>
        </div>
        <div class="title">
          <p>
            您已选择<i>{{ list.length }}</i> 张图片
          </p>
          <p>长按图片选择保存到手机</p>

        </div>
        
      </div>

      <vfooter class="section_2" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      index: 1,
      list: [],
      url: {
        1: "/chexingtansuo",
        2: "/zdyw",
        3: "/zmt",
        4: "/demos",
        5: "/videos",
        6: "/rank",
      },
    };
  },
  async mounted() {
    console.log(this.$route.query.url);

    var that = this;
    this.list = this.$route.query.url.split(",");

    await this.$nextTick(() => {
      var mySwiper = new Swiper(".swiper", {
       // autoplay: true, //可选选项，自动滑动
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          slideChange: function () {
            that.index = this.realIndex + 1;
          },
        },
      });
    });
  },
  methods: {
    downloadFile(url) {
      // var alink = document.createElement("a");
      // alink.href = url;
      // alink.download = "pic"; //图片名
      // alink.click();
      window.location.href=url
    },

    bulkDownload(urlList) {
      let jsonUrlList = this.list;
      let that = this;
      for (let i = 0; i < jsonUrlList.length; i++) {
        //循环遍历调用downloadFile方法
        const url = jsonUrlList[i];
        this.downloadFile(url);
      }
    },
  },
};
</script>
<style lang="css">
.title {
  width: 100%;
}
.title p {
  text-align: center;
  font-family: AlibabaPuHuiTiB;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0em;
  color: #ffffff;
}
.btns {
  width: 497.42px;
  height: 97.54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.39);
  border: 1px solid #ffffff;
  border-radius: 50px;
  font-family: SourceHanSansCN-Regular;
  font-size: 41px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
}
.title p i {
  color: #00f7ff;
}
.swiper-slide {
}
.swiper-slides {
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.swiper-slide-title {
  padding: 20px;
  background-image: url(../assets/images/index/indexsbg.png);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(20px);
}
.swiper-slide-title p:first-child {
  font-size: 26px;
}
.swiper-slide .s {
  display: none;
}
.swiper-slide .ss {
  display: block;
}
.swiper-slide.swiper-slide-active .s {
  display: block;
}
.swiper-slide.swiper-slide-active .ss {
  display: none;
}
.wraps {
  display: flex;
  align-items: center;
  height: calc(100vh - 300px);
  justify-content: center;
  flex-wrap: wrap;
  flex-wrap: wrap;
}
.a1 {
  background: url(../assets/images/index/1b.png);
  background-size: 100% 100%;
}
.a2 {
  background: url(../assets/images/index/2b.png);
  background-size: 100% 100%;
}
.a3 {
  background: url(../assets/images/index/zmt.jpg);
  background-size: 100% 100%;
}
.a4 {
  background: url(../assets/images/index/4b.png);
  background-size: 100% 100%;
}
.a5 {
  background: url(../assets/images/index/5b.png);
  background-size: 100% 100%;
}
.a6 {
  background: url(../assets/images/index/6b.png);
  background-size: 100% 100%;
}
.index .swiper-pagination {
  position: relative !important;
  transform: translateY(-15%);
  bottom: 100px !important;
}
.index .swiper-pagination-bullet {
  background-color: white;
}
</style>
<style scoped lang="css">
.swiper {
  margin-bottom: 20px;
}
.swiper-slide {
  color: aliceblue;
}
/* 轮播图 */
.swiper {
  width: 100vw;
  height: 55vh;
  transform: scale(1.35);
}
.swiper-slide {
  /* width: 50% !important; */
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(1);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1.5) !important;
  z-index: 9;
}

.swiper-slide img {
  width: 100%;
}

.swiper-button-prev {
  left: 30px;
  right: auto;
}

.swiper-button-prev:after {
  content: "none";
}

.swiper-button-next {
  right: 30px;
  left: auto;
}

.swiper-button-next:after {
  content: "none";
}

.group_1 {
  position: relative;
}
.section_2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.page {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  position: relative;
  background: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16524901229737534885.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.section {
  padding-top: 547px;

  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16524901229733289517.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 448px;
}

.section_1 {
  padding: 28px 0 31px;
  background-color: rgba(0, 101, 255, 0.15);
  border-radius: 0px 0px 27px 27px;
  backdrop-filter: blur(40px);
  width: 448px;
}
.text {
  color: rgb(255, 255, 255);
  font-size: 33px;
  line-height: 31px;
  white-space: nowrap;
}
.text_1 {
  margin-top: 13px;
  color: rgb(255, 255, 255);
  font-size: 19px;
  font-weight: 300;
  line-height: 17px;
  white-space: nowrap;
}
</style>
