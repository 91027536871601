var api="http://zyd.kymco.life/";
if(process.env.NODE_ENV=='cy'){
    api="http://cy.hansn.cn/";
}
const lp = "gyyjgg20220523daFSDEFSDKFedfakjkfkdl678ssff";
const getRandom = function (num=12){
  return Math.floor((Math.random()+Math.floor(Math.random()*9+1))*Math.pow(10,num-1));
}
export {
  api,lp,getRandom
}