<template>
  <div class="flex-col page index">
    <div style="min-height: 100vh" :class="'a' + index">
      <vheader class="group_1" />
      <div class="awrap">
      <div thumbsSlider="" class="swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <span class="aboutword">
集团介绍
          </span>
          <img src="@/assets/images/about/1.png" />
        </div>

        <div class="swiper-slide">
          <span class="aboutword">
光阳电动
          </span>

          <img src="@/assets/images/about/4.png" />
        </div>

      </div>
    </div>
       <div

      class="swiper mySwiper2"
    >
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img class="img" src="@/assets/images/about/2.png" />
          <div class="bofang" @click="bofangs(1)">
            <img style="margin-bottom: 0;"  class="img" src="@/assets/images/about/1.gif" />
            <img   class="bofangs" src="@/assets/images/about/play.png" />
          </div>

        </div>
        <div class="swiper-slide">
          <img class="img" src="@/assets/images/about/5.png" />
          <div class="bofang" @click="bofangs(2)">
            <img style="margin-bottom: 0;"  class="img" src="@/assets/images/about/2.gif" />
            <img   class="bofangs" src="@/assets/images/about/play.png" />
          </div>
        </div>


      </div>

    </div>
    </div>

      <vfooter class="section_2" />
    </div>
    <div class="wrap"  v-if="bofang">
      <videoview :videosrcs="videosrc"  :word="false" @close="bofang=false"></videoview>
    </div>
  </div>
</template>

<script>
import videoview from "./videoview.vue";
export default {
  data() {
    return {
      videosrc:null,
       bofang:false,
      index: 0,
      url:{
        1:"/chexingtansuo",
        2:"/zmt",
        3:"/zmt",
        4:"/about",
        5:"/videos",
      }
    };
  },
  components:{videoview},
  async mounted() {
    var that = this;

    await this.$nextTick(() => {
      var swiper = new Swiper(".mySwiper", {
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        watchSlidesProgress: true,
        on:{
          slideChange(){
            //console.log(this.activeIndex)
            that.index=this.activeIndex;
          }
        }
      });
      var swiper2 = new Swiper(".mySwiper2", {
        spaceBetween: 10,
        on:{
          slideChange(){
            that.index=this.activeIndex;
          }
        },
        thumbs: {
          swiper: swiper,
        },
      });
    });
  },
  methods: {
      bofangs(type){
        this.videosrc = type;
        this.bofang=true;
      }
  },
};
</script>

<style scoped lang="css">
  .a0 {
      background: url(../assets/images/about/1bg.png);
      background-size: 100% 100%;
      }
      .a1 {
          background: url(../assets/images/about/2bg.png);
          background-size: 100% 100%;
          }
  .bofang{
    position: relative;
    border-radius: 15px;
    overflow: hidden;
  }
  .bofangs{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 120px !important;
    height: 120px !important;
  }
.mySwiper .swiper-slide{
  display: flex;
  justify-content: center;
  align-items: center;
}
.mySwiper .swiper-slide img{
  width: 100%;
  height: auto;
}
.swiper-slide{
  position: relative;
}
.aboutword{
  font-size: 30px;
  position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 margin: auto;
 color: white;
 display: flex;
 align-items: center;
 justify-content: center;
}
.swiper-slide-thumb-active .aboutword{
   font-size: 40px;
   font-weight: bold;
}
.aboutword.active{

}
.img{
  width: 100%;
  display: block;
  margin:0 0  30px 0;
}
.awrap{
  padding: 0 50px;
}
  .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        text-align: center;
        font-size: 18px;


        /* Center slide text vertically */

      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      body {
        background: #000;
        color: #000;
      }

      .swiper {
        width: 100%;
        height: 130px;
        margin-left: auto;
        margin-right: auto;
      }

      .swiper-slide {
        background-size: cover;
        background-position: center;
      }

      .mySwiper2 {
        height: 80%;
        width: 100%;
      }

      .mySwiper {

        box-sizing: border-box;
        padding: 10px 0 0 0;
      }

      .mySwiper .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
      }

      .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
</style>
