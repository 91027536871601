<template>
  <div class="flex-col page">
    <vheader class="header"></vheader>
    <div class="block"></div>
    <div  class="article-main">
      <cx class="hss"></cx>  
    </div>
  </div>
</template>

<script>
import cx from '@/pages/cx.vue';
export default {
  data() {
    return {
     
    
    };
  },
  components:{
    cx
  },
  methods: {
    
  },
  mounted() {
    //  this.timer = setInterval(this.get, 1500);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
.header >>> .group_1{
  height: 150px;
}
.hss >>> .hub-slider{
  margin-top: 650px;
}
.article-main {

  height: 1540px;
 
}
.banner {
  width: 100%;
}
.arrow-left {
  position: absolute;
  left: 20px;
  top: 250px;
  cursor: pointer;
}
.arrow-right {
  position: absolute;
  left: 280px;
  top: 250px;
  cursor: pointer;
}
.block{
  height: 0px;
  width: 100%;
}
.article-main {
  /* width: 320px; */
  
  overflow: hidden;
  
  position: relative;
}
.banner {
  border-radius: 4px;
  position: absolute;
  transition: all 1s;
}
.wz1 {
  /* width: 280px;
		height: 200px; */
  left: 20px;
  z-index: 777;
  top: 10px;
}
.wz1 img {
  width: 300px;
}
.wz2 {
  /* width: 290px;
		height: 200px; */
  left: 15px;
  top: 100px;
  z-index: 888;
}
.wz3 {
  /* width: 300px;
		height: 200px; */
  left: 10px;
  top: 200px;
  z-index: 999;
}
.dot-view {
  position: absolute;
  left: 120px;
  top: 255px;
  display: flex;
  flex-direction: row;
}
.dot-color-actived {
  background-color: #ff0000;
}
.dot-color-normal {
  background-color: #333;
}
.dot-1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.dot-2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 20px;
}
.dot-3 {
  margin-left: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>

<style scoped lang="css">
.swiper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.smallbg {
  width: 500px;
 
  display: block;
  margin: 0 auto;
}
.image_1 {
  width: 38px;
  height: 26px;
}
.page {
  background-image: radial-gradient(
    70.1% 41.9% at 50% 33.9%,
    rgb(0, 86, 188) 0%,
    rgb(4, 53, 132) 20.3%,
    rgb(1, 13, 121) 37%,
    rgb(0, 0, 0) 100%
  );
  width: 100%;
  overflow-y: auto;
  height: 100%;
}
.section_1 {
  /* padding: 68px 120px 64px 120px;
  background-image: linear-gradient(
    146deg,
    rgba(255, 255, 255, 0.4) -18.2%,
    rgba(0, 86, 255, 0.2) 52.7%,
    rgba(0, 101, 255, 0.4) 110.3%
  );
  width: 100%;
  border-radius: 27px 27px 0px 0px;
  box-shadow: 6.66px 16.66px 13.34px rgba(0, 15, 54, 0.6);
  backdrop-filter: blur(40px); */
}
.image {
  width: 167px;
  height: 28px;
}
.image_2 {
  margin-left: 14px;
}
.image_3 {
  margin-left: 8px;
  width: 38px;
  height: 25px;
}
.image_4 {
  margin-left: 8px;
}
.image_5 {
  margin-left: 5px;
}
.group_1 {
  padding-top: 16px;
  position: relative;
}
.group_2 {
  margin-top: 8px;
  padding: 256px 0 51px;
  position: relative;
}
.image_6 {
  width: 93px;
  height: 47px;
}
.image_7 {
  width: 129px;
  height: 38px;
  position: absolute;
  left: 136px;
  top: 0;
}
.image_8 {
  opacity: 0.43560000000000004;
  width: 392px;
  height: 173px;
}
.image_9 {
  opacity: 0.43560000000000004;
  width: 392px;
  height: 173px;
  position: absolute;
  right: 0;
  bottom: 84px;
}
.image_10 {
  width: 326px;
  height: 352px;
  position: absolute;
  left: 86px;
  top: 0;
}
.group_3 {
  text-align: center;
  width: 251px;
  position: absolute;
  left: 126px;
  bottom: 0;
}
.text {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}
.text_1 {
  color: rgb(255, 255, 255);
  font-size: 26px;
  font-weight: 300;
  line-height: 40px;
}
.text_2 {
  margin-left: 20px;
  color: rgb(255, 255, 255);
  font-size: 26px;
  font-weight: 300;
  line-height: 40px;
}
</style>
