<template>
  <div class="flex-col page">
    <div class="flex-col items-center section_1">
      <div class="flex-col items-center image-wrapper" @click="play" style="position: relative;">
        <!-- <img
          src="@/assets/images/play.png"
          class="image"
        /> -->
        {{videosrcs}}
       <video
       ref="vueMiniPlayer"
													:src="videosrc?$video(videosrc): (videosrcs==1?'http://cdn-image-kymco.hansn.cn/1.mp4':'http://cdn-image-kymco.hansn.cn/2.mp4')"
													style="object-fit: cover;" x5-playsinline="true"
													webkit-playsinline="true" playsinline="true"
													x5-video-player-type="h5" x5-video-player-fullscreen="true"
													x5-video-ignore-metadata="true" autoplay=""
													 loop=""></video>
                           <img v-if="!img" class="jz" style="width:10rem;" src="@/assets/images/play.png" alt="">
      </div>
    </div>
    <div class="flex-col group">
      <div v-if="word" class="justify-evenly group_1">

        <img
        v-if="videotips"
          :src="$img(videotips)"
          class="image_2"
        />
      </div>

      <img
      @click="close"
        src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089476697317630.png"
        class="image_3"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      word:{
        default:true
      },
      videosrc:{
        default:null
      },
      videosrcs:{
        default:null
      },
      videotips:{
         default:null
      }
    },
    data() {
      return {
        img:true,

      };
    },
    mounted() {

    },

    methods:{

      close(){
        this.$emit("close")
      },
      play(){

        if(!this.$refs.vueMiniPlayer.paused){
          this.$refs.vueMiniPlayer.pause();//暂停
          this.img=false;
        }else{
          this.$refs.vueMiniPlayer.play();//播放
          this.img=true;
        }
      }
    }
  };
</script>

<style scoped lang="css">
video{
  width: 100%;
}
  .page {
    background-color: rgb(0, 0, 0);
    width: 100%;
    overflow-y: auto;
    height: 100vh !important;
    background-image: none !important;
  }
  .section_1 {

  }
  .group {
    padding: 57px 126px 48px 131px;
    background-color: black;
  }
  .image-wrapper {
    width: 100%;
    /* padding: 45px 0 32px; */
    /* box-shadow: 0px 2.66px 2.66px rgba(0, 0, 0, 0.25);
    background-color: rgb(51, 51, 51);
    backdrop-filter: blur(25px);
    border-radius: 50%;
    width: 200px;
    border: solid 6.66px rgb(255, 255, 255); */
  }
  .group_1 {
    padding: 0 107px;
  }
  .text {
    margin-top: 49px;
    align-self: center;
    color: white;
    font-size: 60px;
  }
  .image_3 {
    margin-top: 48px;
    align-self: center;
    border-radius: 50%;
    width: 53px;
    height: 53px;
  }
  .image {
    width: 109px;
    height: 109px;
  }
  .image_1 {
    width: 65px;
    height: 28px;
  }
  .image_2 {
    width: 180px;
    height: 29px;
  }
</style>
