<template>
<div @touchstart="start" v-title data-title="光阳电动全国试驾PK赛_苏州站">
  <audio
	  v-if="audio"
	  id="audio"
	  loop
	  preload
	  autoplay
	  src="@/assets/images/demo/1.mp3"
	></audio>
  <div class="fullscreen " id="holder">
    <div class="fullscreen-wp" v-fullscreen="opts" ref="fullscreen">
      <div class="page-1 page">
        <div class="flex-col pages">
          <div class="flex-col">
            <div style="position: relative;top:5rem;">
            <img v-animate="{ value: 'bounceInUp' }" style="width: 45.8rem;margin: 0 auto;display: block;" src="@/assets/images/demo/logo.png" alt="">
            <img v-animate="{ value: 'bounceInUp' }" style="width: 75.1rem;margin: 10rem auto 0;display: block;" src="@/assets/images/demo/9.png" alt=""></img>
            <img
                  v-animate="{ value: 'bounceInLeft' }" 
                    src="@/assets/images/demo/1.png"
                    style="width: 40rem;margin: 3rem auto 0;display: block;"
                  />
             </div>     
            <div class="flex-col group_1">
                <div class="flex-col section_1">
                <div class="justify-evenly group_2">    
                </div>
                <div @click="openvideo(2)" v-animate="{ value: 'bounceInUp' }"  class="flex-col items-center group_3">
                  <img
                    class="w120"
                    src="@/assets/images/demo/paly.png"
                    style="margin: 0 auto;display: block;"
                  />
                  <span class="text fade" >观看S7宣传片</span>
                </div>
                <div  v-animate="{ value: 'bounceInLeft' }"  class="flex-col section_2">
                    <img
                 
                    src="@/assets/images/demo/7.png"
                    style="width: 50rem;margin: 0 auto;display: block;"
                  />
                  <img
                  class="fade"
                 
                    src="@/assets/images/demo/8.png"
                    style="width: 30rem;margin: 3rem auto 0;display: block;"
                  />
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <img  style="width: 5rem;position: absolute;left: 0;right: 0;margin: auto;bottom: 5rem;" src="@/assets/images/demo/down.png" alt="">
      </div>
      <div class="page-2 page">
        <div class="flex-col page2">
  <div class="flex-col section_1">
    <img v-animate="{ value: 'bounceInUp' }" style="width: 75.1rem;margin: 0 auto;display: block;" src="@/assets/images/demo/10.png" alt=""></img>
    <div  @click="openvideo(1)" class="flex-col items-center group_5" v-animate="{ value: 'bounceInUp' }" >
      <img
                    class="w120"
                    src="@/assets/images/demo/paly.png"
                    style="margin: 0 auto;display: block;"
                  />
      <span class="text fade">点击观看视频</span>
    </div>
    <div class="flex-col section_2">
       <img
                  v-animate="{ value: 'bounceInLeft' }" 
                    src="@/assets/images/demo/4.png"
                    style="width:  54.6rem;margin: 0 auto;display: block;"
                  />
    </div>
  </div>
</div>
 <img  style="width: 5rem;position: absolute;left: 0;right: 0;margin: auto;bottom: 5rem;" src="@/assets/images/demo/down.png" alt="">
      </div>
      <div class="page-3 page">
        <div class="flex-col page3">
     <img v-animate="{ value: 'bounceInUp' }" style="width: 45.8rem;margin: 0 auto;display: block;" src="@/assets/images/demo/logo.png" alt="">
            <img v-animate="{ value: 'bounceInUp' }" style="width: 75.1rem;margin: 10rem auto 0;display: block;" src="@/assets/images/demo/9.png" alt=""></img>
     <img
                  v-animate="{ value: 'bounceInLeft' }" 
                    src="@/assets/images/demo/1.png"
                    style="width: 40rem;margin: 3rem auto 0;display: block;"
                  />
  <div class="flex-col group_1">
    
    <div class="flex-col group_5">
      
      <div class="justify-center group_7">
       
        
      </div>
      <div class="flex-row group_8">
          <img
                  v-animate="{ value: 'bounceInRight' }" 
                    src="@/assets/images/demo/5.png"
                    style="width: 70rem;margin: 0 auto;display: block;"
                  />
      </div>
      
      
     <a href="https://apis.map.qq.com/uri/v1/geocoder?coord=31.375657,120.760919&amp;referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77" style="text-decoration:none;text-align: center;" target="_blank">
      <div v-animate="{ value: 'bounceInUp' }"  class="flex-col items-center button"><span class="text fade">点击查看导航</span></div>
      </a>
    </div>
  </div>
  <div v-animate="{ value: 'bounceInUp' }"  class="flex-col items-center section_1" style="position: absolute ;bottom:0;">
    <img style="width: 100%;position: relative;z-index: 10;" src="@/assets/images/demo/code.png" alt="">
   <div class="wgroup">

  
   <div v-animate="{value :'fadeInDown', delay: 600 }" class="group_12">
      <span class="text_1">长按扫描二维码</span><br/>
      <span class="text_2">即刻</span>
      <span class="text_3">预约试驾</span>
    </div>
     </div>
  </div>
</div>
        <!-- <p class="part-3" v-animate="{ value: 'bounceInLeft', delay: 0 }">
          part-3-1
        </p>
        <p class="part-3" v-animate="{ value: 'bounceInRight', delay: 600 }">
          part-3-2
        </p>
        <p class="part-3" v-animate="{ value: 'zoomInDown', delay: 1200 }">
          part-3-3
        </p> -->
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import video from "@/components/video.vue";
export default {
  data() {
    return {
      audio:false,
      opts: {
        start: 0,
        loop: true,
        direction: "v",
        duration: 500,
        preventWechat: true,
        beforeChange: (prev, next) => {},
        afterChange: (prev, next) => {},
      },
    };
  },
  mounted() {
    this.$nextTick(() => {});
    
  },
   async created() {
    let data= await this.service.get("/jssdkss.php?url="+window.location.href);
    
    this.initwx(data);
  },
  methods:{
    start(){
      this.audio=true;
    },
    openvideo(datas){
      this.$modal.open(video, {
  props: {
     datas,
      onOk: () => {this.$modal.close()}
    }
})
    },
    initwx(data) {
     
      var url = ""; //分享的文章地址
      var appId = data.appid;
      var timestamp = data.timestamp;
      var nonceStr = data.noncestr;
      var signature = data.signature;
      var debug = true;
      var link = data.link;
      console.log(timestamp,nonceStr,signature,link)
      wx.config({
        debug: debug , // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: [
           "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",
          
        ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      // var { share_title, share_desc, share_img } = data;
      wx.ready(function () {
        wx.onMenuShareTimeline({
          title: "光阳电动全国试驾PK赛_苏州站", // 分享标题
          desc: "6月18日至19日，苏州618趣试驾邀您来赛！", // 分享描述
          link: link, // 分享链接
          imgUrl: "http://zyd.kymco.life/share.png", // 分享图标
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });
        
          wx.onMenuShareQQ({
          title: "光阳电动全国试驾PK赛_苏州站", // 分享标题
          desc: "6月18日至19日，苏州618趣试驾邀您来赛！", // 分享描述
          link: link, // 分享链接
          imgUrl: "http://zyd.kymco.life/share.png", // 分享图标
          success: function () { 
            // 用户确认分享后执行的回调函数
          },
          cancel: function () { 
            // 用户取消分享后执行的回调函数
          }
          })

        wx.onMenuShareAppMessage({
          title: "光阳电动全国试驾PK赛_苏州站", // 分享标题
          desc: "6月18日至19日，苏州618趣试驾邀您来赛！", // 分享描述
          link: link, // 分享链接
          imgUrl: "http://zyd.kymco.life/share.png", // 分享图标
          type: "", // 分享类型,music、video或link，不填默认为link
          dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });
      });
    },
  }
};
</script>

<style scoped lang="scss">
@keyframes steam {

0%, 100% {

transform: skewX(0deg);

opacity: 1;

}

25% { transform: skewX(10deg); opacity: .8; }

75% { transform: skewX(-10deg); opacity: .8; }

}
.steam {
   
    animation: steam 1000ms infinite;
    
}
.page{
  position: relative;
}
.wgroup{
  overflow: hidden;
  height: 400px;
  margin-top: -160px;
  width: 100%;
}
.page3 {
  padding: 143px 82px 0;
  background-color: #ffffff;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777289349660799.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  .group {
    padding: 0 109.5px;
    .image {
      width: 108px;
      height: 58px;
    }
    .image_1 {
      margin: 9px 0 9px 18px;
      width: 240px;
      height: 40px;
    }
  }
  .group_1 {
    margin-top: 32px;
    .group_2 {
      padding: 0 7px;
      .group_3 {
        margin-top: 14px;
        padding-left: 104px;
        padding-right: 102.5px;
        .image_7 {
          margin: 4px 0;
          width: 104px;
          height: 82px;
        }
        .image_8 {
          margin: 4px 0 4px 12px;
          width: 105px;
          height: 82px;
        }
        .group_4 {
          width: 145px;
          position: relative;
          .image_9 {
            width: 142px;
            height: 89px;
          }
          .image_10 {
            width: 145px;
            height: 90px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
          }
        }
      }
      .equal-division-item {
        flex: 1 1 138px;
        background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777305504403304.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 87px;
      }
      .equal-division-item_1 {
        margin-left: 8px;
        flex: 1 1 138px;
        background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777328046033062.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 87px;
      }
      .equal-division-item_2 {
        margin-left: 8px;
        flex: 1 1 138px;
        background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777307669569288.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 87px;
      }
      .equal-division-item_3 {
        margin-left: 8px;
        flex: 1 1 138px;
        background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777329152834916.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 87px;
      }
      .image_2 {
        flex-shrink: 0;
        width: 138px;
        height: 88px;
      }
    }
    .group_5 {
      margin-top: 0;
      .group_6 {
        align-self: center;
        filter: drop-shadow(0px 4px 2.5px #00000040);
        width: 399.5px;
        .image_11 {
          width: 82.5px;
          height: 35px;
        }
        .image_12 {
          width: 46px;
          height: 38px;
        }
        .image_13 {
          width: 43px;
          height: 37px;
        }
        .image_14 {
          width: 44px;
          height: 38px;
        }
        .image_15 {
          margin-top: 32px;
          width: 28px;
          height: 4px;
        }
        .image_16 {
          width: 45px;
          height: 38px;
        }
        .image_17 {
          width: 43px;
          height: 38px;
        }
        .image_18 {
          width: 46px;
          height: 37px;
        }
      }
      .group_7 {
        margin-top: 0;
        .image_19 {
          width: 126.5px;
          height: 39px;
        }
        .image_20 {
          margin-left: 9px;
          width: 103px;
          height: 39px;
        }
      }
      .group_8 {
        margin-top: 0;
        .image_21 {
          align-self: center;
          width: 163px;
          height: 8px;
        }
        .image_23 {
          margin-left: 44px;
          width: 41px;
          height: 35px;
        }
        .image_27 {
          margin-left: 2px;
        }
        .image_28 {
          margin-left: 2.5px;
        }
        .image_29 {
          margin-left: 42px;
        }
      }
      .group_9 {
        margin-top: 24px;
        .image_30 {
          width: 43px;
          height: 36px;
        }
        .image_31 {
          width: 41px;
          height: 36px;
        }
        .image_32 {
          margin-bottom: 2px;
          width: 42px;
          height: 32px;
        }
        .image_33 {
          width: 44px;
          height: 35px;
        }
        .image_35 {
          width: 43px;
          height: 34px;
        }
        .image_41 {
          width: 43px;
          height: 35px;
        }
        .image_42 {
          width: 30px;
          height: 33px;
        }
      }
      .group_10 {
        margin-top: 10px;
        padding: 0 137.5px;
        .image_48 {
          width: 42px;
          height: 34px;
        }
        .image_51 {
          width: 44px;
          height: 36px;
        }
        .image_53 {
          width: 39px;
          height: 36px;
        }
      }
      .group_11 {
        margin-top: 20px;
        padding-left: 141px;
        padding-right: 139.5px;
        .image_55 {
          width: 33px;
          height: 27px;
        }
        .image_58 {
          width: 31px;
          height: 27px;
        }
        .image_59 {
          width: 31px;
          height: 26px;
        }
        .image_60 {
          width: 32px;
          height: 27px;
        }
        .image_62 {
          width: 29px;
          height: 27px;
        }
      }
      .button {
        margin: 50px auto 0;
        padding: 20.5px 0;
        align-self: center;
        background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777326449226885.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 367px;
        .text {
          color: #00f9ff;
          font-size: 36px;
          font-family: Inter;
          font-weight: 700;
          line-height: 34px;
        }
      }
      .image_24 {
        width: 42px;
        height: 36px;
      }
      .image_26 {
        width: 42px;
        height: 35px;
      }
      .image_46 {
        width: 40px;
        height: 35px;
      }
    }
  }
  .section_1 {
    margin-top: 0;
    
    padding: 0;
    align-self: center;
   
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 320px;
    .group_12 {
      text-align: center;
      padding: 200px 10px 100px;
      
      background: url("../assets/images/demo/bgs.png");
      

      .text_1 {
        color: #ffffff;
        font-size: 40px;
        font-family: Inter;
        line-height: 50px;
        letter-spacing: 2px;
      }
      .text_2 {
        color: #ffffff;
        font-size: 40px;
        font-family: Inter;
        line-height: 50px;
        letter-spacing: 2px;
      }
      .text_3 {
        color: #ffffff;
        font-size: 40px;
        font-family: Inter;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 2px;
      }
    }
  }
}
.page2 {
  padding: 116px 0 117px;
  background-color: #000000;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .section_1 {
    padding: 27px 86px 23px 87px;
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777319505934764.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .group {
      padding: 0 105px;
      .image {
        width: 108px;
        height: 58px;
      }
      .image_1 {
        margin: 9px 0 9px 18px;
        width: 240px;
        height: 40px;
      }
    }
    .group_1 {
      margin-top: 38px;
      .group_2 {
        flex: 1 1 auto;
        .image_2 {
          width: 329px;
          height: 95px;
        }
        .group_3 {
          margin-top: 12.5px;
          padding: 0 15px;
          .image_3 {
            margin-right: 8.5px;
            width: 69px;
            height: 47px;
          }
          .image_4 {
            margin-right: 7px;
            width: 71px;
            height: 47px;
          }
          .image_5 {
            width: 72px;
            height: 47px;
          }
        }
      }
      .group_4 {
        padding-bottom: 34.5px;
        flex-shrink: 0;
        width: 254.5px;
        position: relative;
        .image_6 {
          margin-left: 15.5px;
          width: 239px;
          height: 120px;
        }
        .image_7 {
          width: 70px;
          height: 46px;
          position: absolute;
          left: 0;
          bottom: 0;
        }
        .image_8 {
          width: 69px;
          height: 46px;
          position: absolute;
          left: 76.5px;
          bottom: 0;
        }
      }
    }
    .group_5 {
      margin-top: 356px;
      .image-wrapper {
        padding: 38px 0 39.5px;
        border-radius: 50%;
        background-color: #002526;
        width: 120.5px;
        border: solid 1px #00f9ff;
        .image_9 {
          width: 36px;
          height: 41px;
        }
      }
      .text {
        margin-top: 29.5px;
        color: #00f9ff;
        font-size: 32px;
        font-family: Inter;
        font-weight: 700;
        line-height: 30px;
      }
    }
    .section_2 {
      margin: 313px 15px 0;
      padding: 20px 20px;
      background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551832120266235289.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .equal-division {
        margin-top: 38px;
        align-self: center;
        .equal-division-item {
          margin-left: 12px;
          flex: 1 1 96px;
          width: 94.5px;
          height: 79px;
        }
        .image_16 {
          margin-left: 0;
        }
      }
      .image_10 {
        margin: 3px 0;
        width: 68px;
        height: 73px;
      }
      .image_11 {
        margin: 3px 0 3px 15.5px;
        width: 22px;
        height: 73px;
      }
      .image_12 {
        margin: 3px 0 3px 13px;
        width: 70px;
        height: 73px;
      }
      .image_13 {
        margin-left: 9px;
        width: 97px;
        height: 79px;
      }
      .image_14 {
        margin-left: 16px;
        width: 92px;
        height: 78px;
      }
      .image_15 {
        margin-left: 10.5px;
        width: 93px;
        height: 79px;
      }
    }
  }
}
@keyframes fade {
    from {
        opacity: 1.0;
    }
    50% {
        opacity: 0.4;
    }
    to {
        opacity: 1.0;
    }
}


.fade {
   
    animation: fade 1000ms infinite;
    
}

.w120{
  width: 120px;
}
.fullscreen {
  height: 100vh;
  overflow: hidden;
}
.pages {
  padding: 143px 0 180px;
  
  background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777289349660799.png);
  background-size: 101% auto;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-position: -1px 0%;
  .equal-division {
    margin-top: 32px;
    align-self: center;
    .equal-division-item {
      flex: 1 1 138px;
      background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777305504403304.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 137px;
      height: 87px;
    }
    .equal-division-item_1 {
      margin-left: 8px;
      flex: 1 1 138px;
      background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777307042185044.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 137px;
      height: 87px;
    }
    .equal-division-item_2 {
      margin-left: 8px;
      flex: 1 1 138px;
      background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777307669569288.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 137px;
      height: 87px;
    }
    .equal-division-item_3 {
      margin-left: 8px;
      flex: 1 1 138px;
      background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777308302349668.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 137px;
      height: 87px;
    }
    .image_2 {
      flex-shrink: 0;
      width: 138px;
      height: 88px;
    }
  }
  .group_1 {
    margin-top: -100px;
    padding-top: 0;
    position: relative;
    .section_1 {
      padding-top: 0;
      mix-blend-mode: LIGHTEN;
      overflow: hidden;
      background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629dc33574c9bd0011bdcb2b/16551777299532965345.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .group_2 {
        align-self: center;
        filter: drop-shadow(0px 4px 2.5px #00000040);
        width: 399.5px;
        .image_7 {
          width: 82.5px;
          height: 35px;
        }
        .image_8 {
          width: 46px;
          height: 38px;
        }
        .image_9 {
          width: 43px;
          height: 37px;
        }
        .image_10 {
          width: 44px;
          height: 38px;
        }
        .image_11 {
          margin-top: 32px;
          width: 28px;
          height: 4px;
        }
        .image_14 {
          width: 43px;
          height: 38px;
        }
        .image_15 {
          width: 46px;
          height: 37px;
        }
      }
      .group_3 {
        padding: 403.5px 0;
        .image-wrapper {
          padding: 38px 0 39.5px;
          border-radius: 50%;
          background-color: #d9feff;
          width: 120.5px;
          border: solid 1px #00f9ff;
          .image_16 {
            width: 36px;
            height: 41px;
          }
        }
        .text {
          margin-top: 29.5px;
          color: #00f9ff;
          font-size: 32px;
          font-family: Inter;
          font-weight: 700;
          line-height: 30px;
        }
      }
      .section_2 {
        margin-top: 0;
        padding: 7px 124px 28.5px;
        background-image: linear-gradient(0deg, #080808 23.6%, #07090c00 100%);
        .group_5 {
          margin-top: 0;
          padding: 0 92px;
          .image_24 {
            width: 73px;
            height: 59px;
          }
          .image_25 {
            width: 72px;
            height: 58px;
          }
          .image_26 {
            width: 70px;
            height: 59px;
          }
          .image_27 {
            width: 72px;
            height: 59px;
          }
          .image_28 {
            margin: 2.5px 0;
            width: 16px;
            height: 54px;
          }
        }
        .image_17 {
          width: 126.5px;
          height: 39px;
        }
        .image_18 {
          margin-left: 9px;
          width: 103px;
          height: 39px;
        }
        .image_19 {
          margin-left: 23.5px;
          width: 47px;
          height: 39px;
        }
        .image_20 {
          margin-left: 2px;
          width: 44px;
          height: 39px;
        }
        .image_21 {
          margin-left: 5.5px;
        }
        .image_22 {
          width: 45px;
          height: 39px;
        }
        .image_23 {
          margin-left: 4px;
        }
      }
      .image_12 {
        width: 45px;
        height: 38px;
      }
    }
    .image_29 {
      width: 104px;
      height: 82px;
      position: absolute;
      left: 193px;
      top: 4px;
    }
    .image_30 {
      width: 105px;
      height: 82px;
      position: absolute;
      left: 308.5px;
      top: 4px;
    }
    .image_31 {
      width: 142px;
      height: 89px;
      position: absolute;
      right: 192.5px;
      top: 0;
    }
    .image_32 {
      width: 145px;
      height: 90px;
      position: absolute;
      right: 191.5px;
      top: 0;
    }
  }
  .image {
    width: 108px;
    height: 58px;
  }
  .image_1 {
    margin: 9px 0 9px 18px;
    width: 240px;
    height: 40px;
  }
}
</style>
