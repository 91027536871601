<template>
<div style="padding: 30px ;">
    <h1 style="font-size: 5vmin;text-align: center;">
    请在谷歌浏览器中打开
  </h1>
  <h1 style="font-size: 5vmin;">
    {{form.llqsjs}}
  </h1>
  <el-form>		
			<el-form-item label="店名"  prop="title" >
				<el-input v-model="form.name" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="手机号码"  prop="orders" >
				<el-input v-model="form.mobile" autocomplete="off"></el-input>
			</el-form-item>
  <p>
    <el-button class="mb20" style="width: 100%;" @click="submit" type="primary">提交</el-button>
  </p>
  </el-form>
  </div>
</template>

<script>
export default {
  name: 'WorkspaceJsonFig',

  data() {
    return {
      llqsjs:"",
      form:{
        llqsjs:"",
        name:"",
        mobile:""
      }
    };
  },

  mounted() {
     this.gets()
  },

  methods: {
    async submit(){
      if(this.form.name==''||this.form.mobile==''){
        this.$message.error("请将您的手机号码和店名填写完整")
        return
      }
       let {data} =   await this.service.post("/index.php/index/goodses/shopadd",this.form)
       this.$message.success("提交成功，请将您的手机号码和店名发送给工作人员")
       setTimeout(()=>{
         this.nav('index')
       },3000)
    },
    gets(){
      if(localStorage.getItem('browserId')){
        this.form.llqsjs=localStorage.getItem('browserId')
      }else{
        setTimeout(()=>{
          this.gets()
        },500)
      }
      
    }
  },
};
</script>

<style lang="postcss" scoped>

</style>
