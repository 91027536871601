import createPersistedState from "vuex-persistedstate";
import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user:process.env.NODE_ENV === 'production'? null:null
    //  {
    //   xing:"李",
    //   username: "李兴会",
    //   nickname: "西瓜拌饭",
    //   avatar:
    //     "https://thirdwx.qlogo.cn/mmopen/vi_32/lwy6Y5ybTj2Pv1qo7NbMOOGiaeic87oCCTyOjHfOSvic8o0Jax1C7tumKoib7XrQjXiaTtVl8OaDMgdGjEogCwBIj4A/132",
    //   mobile: "18861856357",
    //   email: "2090334844@qq.com",
    //   kind: 2,
    //   hospital: "",
    //   department: "",
    //   provinces: "江苏省",
    //   city: "常州市",
    //   area: "",
    //   unionid: "o2pZEwwtjiyY4Ae3OGg45OT_WXfM",
    //   add_time: "2022-04-29 15:25:19"
    // }
    ,
    bqList:[],
    city:"苏州"
  },
  mutations: {
    setCity(state, data){
      state.city = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    setBqList(state, data) {
      state.bqList = data;
    },
  },
  plugins: [createPersistedState()],
});
export default store;
