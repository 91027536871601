<template>
  <div class="flex-col" @click="nav('index')">
  <div class="flex-col items-center group_1">
    
    <img
      src="@/assets/images/logo.png"
      class="image_1"
    />
   
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {};
    },
  };
</script>

<style scoped lang="css">
  .group_1 {
  position: relative;
  height: 200px;
}
.image {
  opacity: 0.9;
  width: 720px;
  height: 1280px;
}
.image_1 {
  width: 480px;
 
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 91px;
}
.image_2 {
  width: 38px;
  height: 26px;
  position: absolute;
  right: 225px;
  top: 91px;
}
.image_3 {
  width: 38px;
  height: 25px;
  position: absolute;
  right: 271px;
  top: 91px;
}
.image_4 {
  width: 38px;
  height: 26px;
  position: absolute;
  right: 317px;
  top: 91px;
}
.image_5 {
  width: 167px;
  height: 28px;
  position: absolute;
  left: 184px;
  top: 90px;
}
</style>