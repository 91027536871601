<template>
  <div class="flex-col page video" style="overflow-y: scroll" v-swipeup="handlerScroll">
    <vheader></vheader>
    <div class="rank">
      <img class="ranks" src="@/assets/images/rank/img.png" alt="" />
      <div style="position:relative;">
        <div @click="cityshow=!cityshow" class="items-center justify-center" style="text-align: center;font-family: AlibabaPuHuiTiB;font-size: 4.8rem;font-weight: bold;color: #fff;margin-bottom: 5rem;"><span>{{citylist[indexCity]}}站</span> <img style="width: 4rem;margin-left: 1.5rem;"
            src="@/assets/images/rank/bottom.png" class="simg" :class="cityshow?'simgactive':''" alt=""> </div>
        <transition name="aa">
        <div v-show="cityshow" class="new-select">
            <div @click="selectCity(i)" v-for="(t,i) in citylist">{{t}}站</div>
           
        </div>    
        </transition>
      </div>
      <!-- <div v-show="!isStart" class="title"></div>
      <div v-show="isStart && !isEnd" class="title">
        本轮倒计时：<times
         v-if="timec"
          @res="init"
          :start="startTime"
          :duration="timec * 60 * 60 * 1000"
        ></times>
      </div>
      <div v-show="isEnd" class="title">本轮试驾已结束</div> -->
      <div class="jt">
        <img class="ranks" src="@/assets/images/rank/bg.png" alt="" />
        <div class="jts">
          <div>
            <p>{{ list[1] ? list[1].name ? list[1].name : $name(list[1].numbers) : "暂无" }}</p>
            <p>NO.2</p>
            <p>{{ list[1].sjtime ? list[1].sjtime : "暂无" }}</p>
          </div>
          <div class="tops">
            <p>{{ list[0] ? list[0].name ? list[0].name : $name(list[0].numbers) : "暂无" }}</p>
            <p>NO.1</p>
            <p>{{ list[0].sjtime ? list[0].sjtime : "暂无" }}</p>
          </div>
          <div>
            <p>{{ list[2] ? list[2].name ? list[2].name : $name(list[2].numbers) : "暂无" }}</p>
            <p>NO.3</p>
            <p>{{ list[2].sjtime ? list[2].sjtime : "暂无" }}</p>
          </div>
        </div>
      </div>
      <div style="height: 6.6rem"></div>
      <div class="jtlist" style="background-color: transparent;">
        <div class="jtlist1" style="visibility: hidden">1</div>
        <div class="jtlist2 a">
          <p class="jtlist2p1">试驾名单</p>
          <p class="jtlist2p1">试驾成绩</p>
        </div>
      </div>
      <div v-if="list.length > 3" style="height:calc(100vh - 90rem) ;overflow:auto;">
        <div v-for="(t, i) in list" v-if="i > 2" class="jtlist">
          <div class="jtlist1">{{ i + 1 }}</div>
          <div class="jtlist2">
            <p class="jtlist2p1">{{ t.name ? t.name : $name(t.numbers) }}</p>
            <p class="jtlist2p2">{{ t.sjtime && t.sjtime }}</p>
          </div>
        </div>
      </div>
      <div class="down" v-if="list.length > 9 && !shows">向下滑动查看更多</div>
      <div style="height: 5rem"></div>
    </div>
    <vfooter></vfooter>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ranks from "./ranks.vue";
import times from "./time.vue";
import dayjs from "dayjs";
export default {
  data() {
    return {
      indexCity:0,
      citylist:[
        "苏州",
        "郑州",
        "成都",
        "西安"
      ],
      cityshow:false,
      videotips: null,
      bofang: false,
      videosrc: "",
      tab: 0,
      list: [],
      lists: [],
      shows: false,
      page: 1,

      startTime: "",
      endTime: "",
      isStart: true,
      isEnd: false,
      timec: "",
      stime: null
    };
  },
  components: { ranks, times },
  async mounted() {
  
    this.indexCity= this.citylist.findIndex((val)=>val==this.$store.state.city)
    this.init();
    
  },
  destroyed() {
    clearTimeout(this.stime)
  },
  computed: {
    ...mapState(["city"]),
  },
  methods: {
    selectCity(i){
      this.indexCity=i;
      this.cityshow=false;
      this.page=1;
      this.getData();
    },
    countTime(leftTime) {

      var d, h, m, s, ms
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 100 / 60 / 60 / 24)
        h = Math.floor(((leftTime / 100 / 60 / 60) % 24) + 24 * d)
        m = Math.floor((leftTime / 100 / 60) % 60)
        s = Math.floor((leftTime / 100) % 60)
        ms = Math.floor(leftTime % 100)
        if (ms < 10) {
          ms = '0' + ms
        }
        if (s < 10) {
          s = '0' + s
        }
        if (m < 10) {
          m = '0' + m
        }
        // if(h < 10) {
        h = h
        // }
      } else {
        // console.log('已截止')
      }
      //将倒计时赋值到div中
      this.date_d = d  //这里是渲染的天数
      this.date_h = h  //这里是渲染的小时
      this.date_m = m  //这里是渲染的分钟
      this.date_s = s  //这里是渲染的秒数
      this.date_ms = ms  //这里是渲染的毫秒
      return `${m}分${s}秒`
    },
    async init() {
      this.getData();
      this.stime = setInterval(() => {
        this.page = 1
        this.shows = false;
        this.getData();
      }, 60 * 1000)
      this.getTime();
    },
    async getTime() {
      let { data } = await this.service.post("/index.php/index/goodses/sjdjs", {
        llqsjs: localStorage.getItem("browserId"),// localStorage.getItem("browserId"), //"f23ac4f790bf7e752c3794c86dac45d4", //,
      });
      this.startTime = data[0].starttime;
      this.endTime = data[0].endtime;
      this.isStart = dayjs().isAfter(dayjs(this.startTime));

      if (this.isStart) {
        this.isEnd = dayjs().isAfter(dayjs(this.endTime));
      }
      this.timec = dayjs(this.endTime).diff(dayjs(this.startTime), "hour");
    },
    handlerScroll(e) {
      this.page++;
      this.shows = true;
      this.getData();
    },
    async getData() {

      let { data } = await this.service.post(
        "/index.php/index/goodses/sjsort",
        {
          llqsjs: localStorage.getItem("browserId"), // localStorage.getItem("browserId"), //"f23ac4f790bf7e752c3794c86dac45d4", //,
          page: this.page,
          pagesize: 10,
          city:this.citylist[this.indexCity]
        }
      );
      if (this.page == 1) {
        this.list = data;
        if (this.list.length < 10) {
          for (var i = this.list.length; i < 10; i++) {
            this.list.push({ sjtime: "", name: "暂无" })
            this.shows = true;
          }
        }
      } else {
        this.list = [...this.list, ...data];
      }
      this.list.forEach(t => {
        t.sjtime = t.sjtime + ""
        if (!t.sjtime.includes("分")) {
          t.sjtime = this.countTime(t.sjtime)
        }
      })
      // var chunk = 10;
      // var len = data.length;
      // var result = [];
      // for (let i = 0; i < len; i += chunk) {
      //   result.push(data.slice(i, i + chunk)); // 每10项分成一组
      // }
      // if (result.length == 0) {
      //   this.list.push(data);
      // } else {
      //   this.list = result;
      // }

      // await this.$nextTick(() => {
      //   var mySwiper = new Swiper(".swiper", {
      //     //autoplay: true, //可选选项，自动滑动
      //     slidesPerView: 1,
      //     scrollbar: {
      //       el: ".swiper-scrollbar",
      //     },
      //     navigation: {
      //       nextEl: ".swiper-button-next",
      //       prevEl: ".swiper-button-prev",
      //     },
      //   });
      // });
    },
  },
};
</script>
<style lang="css" scoped>
 .aa-enter-active{
	    animation: run .5s;
	  }
	  .aa-leave-active{
	    animation: run .5s reverse;
	  }
	  @keyframes run {
	    0% {
	      opacity: 0;
	    }
	    100% {
	      opacity: 1;
	    }
	  }
.simg{
  transition: all .5s;
}
.simgactive{
  transform: rotate(180deg);
}
.new-select{
  position: absolute;
  top: 80px;
  background-color: rgba(34, 34, 53, .6);
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  
}

.new-select > div{
  padding:14px 70px;
  color: #fff;
  font-family: AlibabaPuHuiTiR;
font-size: 42px;
font-weight: normal;
}

.down {
  font-family: SourceHanSansCN-Regular;
  font-size: 36px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #ffffff;
  margin-top: 30px;
  text-align: center;
}

.rank {
  padding: 0 30px;
}

.ranks {
  width: 100%;
  display: block;
}

.title {
  font-family: AlibabaPuHuiTiB;
  font-size: 48px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.title i {
  color: #0044ff;
  font-style: normal;
}

.jt {
  margin-top: 30px;
  position: relative;
}

.jts {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.jts>div {
  width: 33.333%;
}

.jts>div p:nth-child(1) {
  font-family: AlibabaPuHuiTiB;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0em;
  color: #ffffff;
  text-align: center;
}

.jts>div p:nth-child(2) {
  font-family: AlibabaPuHuiTiB;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0em;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
}

.jts>div p:nth-child(3) {
  font-family: AlibabaPuHuiTiB;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0em;
  color: #00d4ff;
  text-align: center;
}

.tops {
  position: relative;
  top: -40px;
}

.jtlist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.18);
  padding-left: 50px;
  margin-bottom: 30px;
}

.jtlist1 {
  width: 50rpx;
  font-family: AlibabaPuHuiTiB;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0em;
  color: #ffffff;
}

.jtlist2.a {
  background: transparent;
}

.jtlist2 {
  display: inline-flex;
  flex: 1;


  padding: 30px 0;
}

.jtlist2 p {
  width: 50%;
  text-align: center;
}

.jtlist2p1 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0em;
  color: #ffffff;
}

.jtlist2p2 {
  font-family: AlibabaPuHuiTiB;
  font-size: 36px;
  font-weight: bold;

  letter-spacing: 0em;
  color: #00d4ff;
}
</style>
<style scoped lang="css">
.swiper {
  width: 90% !important;
}

.video .swiper-button-next {
  right: -0px;
  background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089420952833555.png) !important;
  background-size: 100% 100% !important;
  width: 64px !important;
  height: 64px !important;
}

.video .swiper-button-next::after {
  content: "";
}

.video .swiper-button-prev {
  left: -0px;
  background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089421562351849.png) !important;
  background-size: 100% 100% !important;
  width: 64px !important;
  height: 64px !important;
}

.video .swiper-button-prev::after {
  content: "";
}
</style>
<style scoped lang="css">
.swiper {
  width: 100%;
  overflow: hidden;
}

.h100 {
  height: 100px;
}

.image_1 {
  width: 38px;
  height: 26px;
}

.text_1 {
  opacity: 0.7;
}

.center-text-wrapper {
  width: 121px;
}

.image_6 {
  width: 67px;
  height: 67px;
}

.image_8 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 67px;
  height: 67px;
}

.page {
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16524901229737534885.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  height: 100vh;
}

.line-tabs {
  margin-top: 39px;
}

.image {
  width: 167px;
  height: 28px;
}

.image_2 {
  margin-left: 14px;
}

.image_3 {
  margin-left: 8px;
  width: 38px;
  height: 25px;
}

.image_4 {
  margin-left: 8px;
}

.image_5 {
  margin-left: 5px;
}

.group_1 {
  padding: 0 77px 20px 95px;
}

.group_2 {
  padding: 20px 0 14px;
}

.text {
  border-bottom: 5px solid rgb(0, 71, 255);
  padding-bottom: 10px;
}

.boractive {}

.section_2 {
  margin-left: 26px;
  margin-right: 26px;
  padding: 70px 14px 73px 14px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 15px;
  white-space: nowrap;
  border-radius: 27px;
  backdrop-filter: blur(40px);
  box-shadow: 0px 2.66px 2.66px rgba(0, 0, 0, 0.25);
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089414715059121.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section_3 {
  margin-top: 237px;
  padding: 6px 77px 5px 110px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
}

.text_3 {
  margin-top: 6px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 300;
  line-height: 19px;
  white-space: nowrap;
}

.text_4 {
  margin-top: 6px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
}

.equal-division {
  margin-top: -12px;
  align-self: center;
  position: relative;
}

.text-wrapper {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  white-space: nowrap;
}

.view {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  white-space: nowrap;
}

.view_1 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  line-height: 19px;
  white-space: nowrap;
}

.text {
  color: rgb(255, 255, 255);
  font-size: 27px;
  font-weight: 600;
  line-height: 25px;
  white-space: nowrap;
}

.text_2 {
  margin-top: 6px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 300;
  line-height: 19px;
  white-space: nowrap;
}

.group_6 {
  flex: 1 1 290px;
}

.group_8 {
  margin-left: 60px;
  flex: 1 1 290px;
}

.equal-division-item {
  padding: 30px;
  flex: 1 1 260px;
  width: 260px;
}

.equal-division-item_1 {
  padding: 30px;
  flex: 1 1 260px;
  width: 260px;
}

.image_7 {
  margin-top: 261px;
}

.group_7 {
  margin-left: 23px;
  flex: 1 1 auto;
}

.group_9 {
  flex: 1 1 auto;
}

.image_11 {
  margin-left: 23px;
  margin-top: 261px;
}

.image-wrapper_2 {
  padding: 100px 0 100px;

  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_7 {
  margin-top: 18px;
  align-self: center;
}

.image-wrapper_3 {
  padding: 100px 0 100px;
  filter: drop-shadow(3.34px 3.34px 5px rgba(0, 0, 0, 0.4));
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089414733774101.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_8 {
  margin-top: 14px;
  align-self: center;
}

.image-wrapper {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.videocar {
  width: 100%;
}

.text_5 {
  margin-top: 18px;
  align-self: center;
}

.image-wrapper_1 {
  padding: 100px 0 100px;
  filter: drop-shadow(3.34px 3.34px 5px rgba(0, 0, 0, 0.4));
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089414734931234.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_6 {
  margin-top: 18px;
  align-self: center;
}
</style>
