<template>
  <div>
    <div class="flex-col" style="width: 100%;">
		<div class="justify-between section_4">
			<div class="flex-col items-center w1s">
				<span class="text_5" style="color: white;margin-top: 0;">排名</span>
				<span v-for="(t,i) in datas" :class="i==0?'text_5':'text_6'">{{datap+i+1}}</span>
				<!-- <span class="text_6">代用名</span> -->
				
			</div>
			<div class="flex-row">
				<div class="flex-col items-center group_4">
					<span class="text_15" style="color: white;margin-top: 0;">姓名</span>
					<span v-for="(t,i) in datas" style="color:white;" :class="i==0?'text_15':'text_16'">{{t.name||'暂无用户名'}}</span>
				</div>
				<div class="flex-col items-center group_5">
					<span class="text_25" style="color: white;margin-top: 0;">试驾用时</span>
					<span v-for="(t,i) in datas" class="text_25">{{t.sjtime}}s</span>	
				</div>
			</div>
		</div>
	</div>
  </div>
</template>

<script>
	export default {
		props:["datas","datap"],
		data() {
			return {
				city: uni.getStorageSync("city"),

			}
		},
		methods: {
			// let mphone = number.substring(0, 3) + '****' + number.substring(7);

			doshop() {
				this.$emit("doshop", 0)
			},
			call(phoneNumber) {
				uni.makePhoneCall({
					phoneNumber //仅为示例
				});
			}
		}
	}
</script>

<style scoped>
	.w1s{
		width: 191px;
	}
	.section_4 {
		padding: 0px 0px 0 0px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16546853244557747658.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		 height: 1348px;

	}

	.button {
		margin: 87px 14px 0 20px;
		padding: 22px 0 22.5px;
		background-color: #0086ff;
		border-radius: 90px;
	}

	.text_35 {
		color: #ffffff;
		font-size: 28px;
		font-family: Inter;
		font-weight: 900;
		line-height: 25.5px;
		letter-spacing: 5.6px;
	}

	.text_5 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_6 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_7 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_8 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_9 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_10 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_11 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_12 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_13 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_14 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_15 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_16 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_17 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_18 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_19 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_20 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_21 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_22 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_23 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_24 {
		color: #0065ff;
		font-size: 48px;
		font-family: Inter;
		line-height: 40.5px;
	}

	.text_25 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_26 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_27 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_28 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_29 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_30 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_31 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_32 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_33 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.text_34 {
		color: #ffffff;
		font-size: 48px;
		font-family: Inter;
		line-height: 115px;margin-top:10px;
	}

	.group_4 {
		margin-top: 2.5px;
		width: 323px;
	}

	.group_5 {
		width: 323px;
		margin-left: 10px;
	}
</style>
