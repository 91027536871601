<template>
  <div class="fixed">
    <div class="flex-col">
      <div class="justify-between section">
        <div class="flex-col text-wrapper">
          <span class="text">{{ time.split(" ")[0] }}</span>
        </div>
        <div class="flex-col items-center center-text-wrapper view">
          <span class="text">{{ time.split(" ")[1] }}</span>
        </div>
        <div class="flex-col items-center center-text-wrapper view_1">
          <span class="text">{{city}}店</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      time: "",
     
    };
  },
  computed: {
    ...mapState(["city"]),
  },
  mounted() {
    this.getTime();
    this.time = dayjs().format("YYYY-MM-DD HH:mm");
    setInterval(() => {
      this.time = dayjs().format("YYYY-MM-DD HH:mm");
    }, 6000);
  },
  methods: {
    async getTime() {
      let { data } = await this.service.post("/index.php/index/goodses/sjdjs", {
        llqsjs: localStorage.getItem("browserId"),
      });
      this.city = data[0].city;
      
    },
  },
};
</script>

<style scoped lang="css">
.center-text-wrapper {
  width: 121px;
}
.fixed {
  position: fixed;
  bottom: 0;
  z-index: 10;
  left: 0;
  right: 0;
}
.text {
  opacity: 0.7;
}
.section {
  padding: 6px 77px 5px 110px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
}
.text-wrapper {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;

  white-space: nowrap;
}
.view {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;

  white-space: nowrap;
}
.view_1 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;

  white-space: nowrap;
}
</style>
