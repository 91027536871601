<template>
  <div @touchstart="start">
    <audio v-if="audio" id="audio" loop preload autoplay src="@/assets/images/demo/1.mp3"></audio>
    <div class="fullscreen fullpage-container" id="holder">
      <div class="fullscreen-wp" v-fullscreen="opts" ref="fullscreen">
        <div class="page-1 page">
          <div class="bg">
            <div class="h30"></div>
            <div class="flex justify-end">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 34.1rem ;" class="block"
                src="@/assets/images/cysjz/1/1.png" alt=""></img>
            </div>
            <div style="margin-top: 4.5rem;" class="flex justify-end">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 55.2rem ;" class="block"
                src="@/assets/images/cysjz/1/2.png" alt=""></img>
            </div>
            <div style="margin-top: 11.9rem;" class="flex justify-end">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 39rem ;" class="block"
                src="@/assets/images/cysjz/1/3.png" alt=""></img>
            </div>
            <div class="flex-row items-end" style="margin-top: 5.4rem;position: relative;">
              <img v-animate="{ value: 'bounceInLeft' }" style="width: 54rem ;margin-left: -5rem;" class="block"
                src="@/assets/images/cysjz/1/4.png" alt=""></img>
              <div style="position: absolute;flex-wrap: wrap;text-align: right;bottom: 6rem;right: 0rem;">
                <img v-animate="{ value: 'bounceInUp' }" class="block" style="width: 24.5rem ;"
                  src="@/assets/images/cysjz/1/5.png" alt=""></img>
                <img v-animate="{ value: 'bounceInUp' }" class="block"
                  style="width: 15.7rem ;margin-top: 5rem;float: right;" src="@/assets/images/cysjz/1/6.png" alt=""></img>
              </div>
            </div>
            <div class="flex-row items-center justify-between newbottom" >
              <div>
                <img v-animate="{ value: 'bounceInUp' }" class="block" style="height: 7rem ;"
                  src="@/assets/images/cysjz/1/8.png" alt=""></img>
                <img v-animate="{ value: 'bounceInUp' }" class="block" style="height: 7rem ;margin-top: 2rem;"
                  src="@/assets/images/cysjz/1/7.png" alt=""></img>
              </div>
              <img v-animate="{ value: 'bounceInUp' }" style="width: 19.8rem ;" class="block"
                src="@/assets/images/cysjz/1/code.png" alt=""></img>

            </div>
          </div>

        </div>
        <div class="page-2 page">
          <div class="bg bgs">
            <div class="h30"></div>
            <div class="flex justify-center">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 58.6rem ;" class="block"
                src="@/assets/images/cysjz/2/1.png" alt=""></img>
            </div>
            <!-- <div class="flex-row items-center justify-between newbottom" >
              <img v-animate="{ value: 'bounceInUp' }" style="width: 45.5rem ;" class="block"
                src="@/assets/images/cysjz/2/2.png" alt=""></img>

              <img v-animate="{ value: 'bounceInUp' }" style="width: 19.8rem ;" class="block"
                src="@/assets/images/cysjz/1/code.png" alt=""></img>

            </div> -->
          </div>
        </div>
        <div class="page-3 page">
          <div class="bg bg3">
            <div class="h80"></div>
            <div style="padding: 0 2.2rem;">
              <div class="flex items-end justify-start">
                <img v-animate="{ value: 'bounceInUp' }" style="height: 6.9rem ;" class="block"
                  src="@/assets/images/cysjz/3/1.png" alt=""></img>
                <img v-animate="{ value: 'bounceInUp' }" style="height: 3.5rem ;" class="block"
                  src="@/assets/images/cysjz/3/2.png" alt=""></img>
              </div>
              <div style="height: 6.4rem;"></div>
              <img v-animate="{ value: 'bounceInUp' }" style="width: 100% ;" class="block"
                src="@/assets/images/cysjz/3/3.png" alt=""></img>
              <div style="height: 1rem;"></div>
              <img v-animate="{ value: 'bounceInUp' }" style="width: 100% ;" class="block"
                src="@/assets/images/cysjz/3/4.png" alt=""></img>
            </div>
          </div>
        </div>
        <div class="page-4 page">
          <div class="bg bg4">
            <div class="h80"></div>
            <div style="padding: 0 2.2rem;">
              <div class="flex items-end justify-start">
                <img v-animate="{ value: 'bounceInUp' }" style="height: 6.9rem ;" class="block"
                  src="@/assets/images/cysjz/4/1.png" alt=""></img>
                <img v-animate="{ value: 'bounceInUp' }" style="height: 3.5rem ;" class="block"
                  src="@/assets/images/cysjz/4/2.png" alt=""></img>
              </div>
              <div style="height: 6.4rem;"></div>
              <img v-animate="{ value: 'bounceInUp' }" style="width: 100% ;" class="block"
                src="@/assets/images/cysjz/4/3.png" alt=""></img>
            </div>
          </div>
        </div>
        <div class="page-5 page">
          <div class="bg bg5">
            <div class="h27"></div>
            <div style="padding: 0 2.2rem;">
              <img v-animate="{ value: 'bounceInUp' }"  class="block newwidth"
                src="@/assets/images/cysjz/5/1.png" alt=""></img>
            </div>
            <div class="h10"></div>
            <div class="flex items-end justify-end bicon">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 22.8rem;" class="block"
                src="@/assets/images/cysjz/5/2.png" alt=""></img>
            </div>
          </div>
        </div>
        <div class="page-6 page">
          <div class="bg bg6">
            <div class="h27"></div>
            <div style="padding: 0 2.2rem;">
              <img v-animate="{ value: 'bounceInUp' }"  class="block newwidth"
                src="@/assets/images/cysjz/6/1.png" alt=""></img>
            </div>
            <div class="h10"></div>
            <div class="flex items-end justify-end bicon">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 22.8rem;" class="block"
                src="@/assets/images/cysjz/5/2.png" alt=""></img>
            </div>
          </div>
        </div>
        <div class="page-7 page">
          <div class="bg bg7">
            <div class="h27"></div>
            <div style="padding: 0 2.2rem;">
           
            <a href="https://apis.map.qq.com/uri/v1/geocoder?coord=38.049932,114.518976&amp;referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77"  style="text-decoration:none;text-align: center;" target="_blank">
     
              <img v-animate="{ value: 'bounceInUp' }"  class="block newwidth"
                src="@/assets/images/cysjz/7/1.png" alt=""></img>
                 </a>
            </div>
            <div class="h10"></div>
            <div class="flex items-end justify-end bicon">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 22.8rem;" class="block"
                src="@/assets/images/cysjz/5/2.png" alt=""></img>
            </div>
          </div>
        </div>
        <div class="page-8 page">
          <div class="bg bg8">
            <div class="h27"></div>
            <div style="padding: 0 2.2rem;">
              <img v-animate="{ value: 'bounceInUp' }"  class="block newwidth"
                src="@/assets/images/cysjz/8/1.png" alt=""></img>
            </div>
            <div class="h10"></div>
            <div class="flex items-end justify-end bicon">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 22.8rem;" class="block"
                src="@/assets/images/cysjz/5/2.png" alt=""></img>
            </div>
          </div>
        </div>
        <div class="page-9 page">
          <div class="bg bg9">
            <div class="h27"></div>
            <div style="padding: 0 2.2rem;">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 100% ;" class="block"
                src="@/assets/images/cysjz/9/1.png" alt=""></img>
                 <div style="height: 2rem;"></div>
                <img v-animate="{ value: 'bounceInUp' }" style="width: 100% ;" class="block"
                src="@/assets/images/cysjz/9/2.png" alt=""></img>
            </div>
            <div class="h10"></div>
            <div class="flex items-end justify-end bicon">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 22.8rem;" class="block "
                src="@/assets/images/cysjz/5/2.png" alt=""></img>
            </div>
          </div>
        </div>
        <div class="page-10 page">
          <div class="bg bg10">
            <div class="h27" style="height: 23rem;"></div>
            <div v-animate="{ value: 'bounceInUp' }" style="border-radius: 2rem;overflow: hidden;position: relative;width: 80%;" class="newwidth">
            <div style="position:absolute;top: 0;height: 8rem;background-color: #fff;width: 100%;z-index: 999;"></div>
              <img  style="width: 100%;"  class="block "
                src="@/assets/images/cysjz/10/1.gif" alt=""></img>       
                <div style="position:absolute;bottom: 0;height: 8rem;background-color: #fff;width: 100%;z-index: 999;"></div>    
            </div>
            <div class="h10"></div>
            <div class="flex items-end justify-end bicon">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 22.8rem;" class="block "
                src="@/assets/images/cysjz/5/2.png" alt=""></img>
            </div>
          </div>
        </div>
        <div class="page-11 page">
          <div class="bg bg10">
            <div class="h27" style="height: 23rem;"></div>
            <div  v-animate="{ value: 'bounceInUp' }" style="border-radius: 2rem;overflow: hidden;position: relative;width: 80%;" class="newwidth">
            <div style="position:absolute;top: 0;height: 8rem;background-color: #fff;width: 100%;z-index: 999;"></div>
              <img  style="width: 100%;"  class="block "
                src="@/assets/images/cysjz/10/1.gif" alt=""></img>       
                <div style="position:absolute;bottom: 0;height: 8rem;background-color: #fff;width: 100%;z-index: 999;"></div>    
            </div>
            <div class="h10"></div>
            <div class="flex items-end justify-end bicon">
              <img v-animate="{ value: 'bounceInUp' }" style="width: 22.8rem;" class="block "
                src="@/assets/images/cysjz/5/2.png" alt=""></img>
            </div>
          </div>
        </div>
         <div class="page-12 page">
          <div class="bg bg12">
            <div class="h27"></div>
            <div style="padding: 0 2.2rem;" >
              <img v-animate="{ value: 'bounceInUp' }" style="width: 50.2rem ;margin: 0 auto;" class="block"
                src="@/assets/images/cysjz/12/1.png" alt=""></img>
                 <div style="height: 10.8rem;"></div>
                <!-- <img v-animate="{ value: 'bounceInUp' }" style="width: 46.8rem ;margin: 0 auto;" class="block"
                src="@/assets/images/cysjz/5/2.png" alt=""></img> -->
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 获取地图scheme， 经纬度坐标类型要求为gcj02不然位置会有偏差from 从哪里
const getMapScheme = (from, to, mapType = "gaode") => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端

  const andriodBaidu = (to) => {
    return `bdapp://map/direction?destination=name:${to.name}|latlng:${to.latitude},${to.longitude}&coord_type=gcj02&mode=driving&src=andr.jianghu.jianhao`
  }

  const iOSBaidu = (to) => {
    return `baidumap://map/direction?destination=name:${to.name}|latlng:${to.latitude},${to.longitude}&coord_type=gcj02&mode=driving&src=ios.jianghu.jianhao`
  }

  const andriodGaode = (to) => {
    return `amapuri://route/plan/?sourceApplication=mhc&dlat=${to.latitude}&dlon=${to.longitude}&dname=${to.name}&dev=0&t=0`
  }

  const iOSGaode = (to) => {
    return `iosamap://path?sourceApplication=mhc&dlat=${to.latitude}&dlon=${to.longitude}&dname=${to.name}&dev=0&t=0`
  }
  if(mapType == "baidu") {
    if(isAndroid) {
      return andriodBaidu(to)
    } else {
      return iOSBaidu(to)
    }
  } else if(mapType == "gaode") {
    if (isAndroid) {
      return andriodGaode(to)
    } else {
      return iOSGaode(to)
    }
  }
}


export default {


  data() {
    return {
      audio: false,
      baiduUrl:"",
      opts: {
        start: 0,
        loop: true,
        direction: "v",
        duration: 500,
        preventWechat: true,
        beforeChange: (prev, next) => { },
        afterChange: (prev, next) => { },
      },
    };
  },
  mounted() {
    this.$nextTick(() => { });

  },
  async created() {
    let data = await this.service.get("/jssdk.php?url=" + window.location.href);
  
const latitude =114.518976;
const longitude = 38.049932; 
const from = {}  // 出发地，不传则默认当前位置
const to = { name: "石家庄人民广场美居酒店3楼嘉华厅", longitude, latitude }; // address：目的地
const gaodeUrl = getMapScheme(from, to, "gaode")
const baiduUrl = getMapScheme(from, to, "baidu")
  
    this.baiduUrl=baiduUrl;
    this.initwx(data);
  },
  methods: {
    start() {
      this.audio = true;
    },

    initwx(data) {
        var title="创英·研学堂 | 无牙颌种植与修复方案的设计";
        var desc="石家庄站-7月27日";
        var imgUrl="http://zyd.kymco.life/cy.png";
      var url = ""; //分享的文章地址
       var appId = data.appId;
      var timestamp = data.timestamp;
      var nonceStr = data.nonceStr;
      var signature = data.signature;
      var debug = true;
      var link = data.url;
      console.log(timestamp, nonceStr, signature, link)
      wx.config({
        debug: debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: [
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",

        ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      // var { share_title, share_desc, share_img } = data;
      wx.ready(function () {
        wx.onMenuShareTimeline({
          title, // 分享标题
          desc, // 分享描述
          link: link, // 分享链接
          imgUrl, // 分享图标
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });

        wx.onMenuShareQQ({
          title, // 分享标题
          desc, // 分享描述
          link: link, // 分享链接
          imgUrl, // 分享图标
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
          
        })

        wx.onMenuShareAppMessage({
          title, // 分享标题
          desc, // 分享描述
          link: link, // 分享链接
          imgUrl, // 分享图标
          type: "", // 分享类型,music、video或link，不填默认为link
          dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });
      });
    },
  }
};
</script>
<style>
html {
  font-size: 1.33333vw !important;
}
</style>

<style scoped lang="scss">

.newwidth{
  width: 85%;
  margin: 0 auto;
}
.newbottom{
  position: absolute;
  bottom: 92px;
  left: 4rem;
  right: 4rem;
}
.h30{
  height: 25rem;
}
.h10{
  height: 10rem;
}
.h27{
  height: 27rem;
}
.h80{
  height: 70rem;
}
.bicon{
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: none;
}
@mixin bgleft{
   background-size: 101% 101%;
  background-position: -8px -8px;

} 
.bg {
  height: 100vh;
  background: url('../../assets/images/cysjz/1/bg.png') no-repeat;
 
  padding: 0 40px;
   @include bgleft;
}

.bgs {
  background: url('../../assets/images/cysjz/2/bg.png') no-repeat;
 @include bgleft;
}

.bg3 {
  background: url('../../assets/images/cysjz/3/bg.png') no-repeat;
 @include bgleft;
}

.bg4 {
  background: url('../../assets/images/cysjz/4/bg.png') no-repeat;
  @include bgleft;
}

.bg5 {
  background: url('../../assets/images/cysjz/5/bg.png') no-repeat;
 @include bgleft;
}
.bg6 {
  background: url('../../assets/images/cysjz/6/bg.png') no-repeat;
 @include bgleft;
}
.bg7 {
  background: url('../../assets/images/cysjz/7/bg.png') no-repeat;
  @include bgleft;
}
.bg8 {
  background: url('../../assets/images/cysjz/8/bg.png') no-repeat;
 @include bgleft;
}
.bg9 {
  background: url('../../assets/images/cysjz/9/bg.png') no-repeat;
 @include bgleft;
}
.bg10 {
  background: url('../../assets/images/cysjz/10/bg.png') no-repeat;
  @include bgleft;
}
.bg12 {
  background: url('../../assets/images/cysjz/12/bg.png') no-repeat;
 @include bgleft;
}
.fullpage-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .page {
    width: 100%;
    height: 100%;
  }
}

.page {
  position: relative;
}
@keyframes steam {

  0%,
  100% {

    transform: skewX(0deg);

    opacity: 1;

  }

  25% {
    transform: skewX(10deg);
    opacity: .8;
  }

  75% {
    transform: skewX(-10deg);
    opacity: .8;
  }

}

.steam {

  animation: steam 1000ms infinite;

}

.block {
  display: block;

}


</style>
