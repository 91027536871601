<template>
  <div class="flex-col carimg" style="width:92%;">
    <div class="swiper-button-prev"></div>
    <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
    <div class="swiper-button-next"></div>
    <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
    <div class="swiper">
      <div v-if="list" class="swiper-wrapper">
        <div v-for="(t, i) in list" class="swiper-slide">
          <div class="flex-row justify-between">
            <div class="flex-row items-center equal-division-item">
              <div v-for="(item, is) in t" @click="selects(i, is)" class="m30">
                <div>
                  <img :src="$img(item.img)" class="image" />
                  <img v-show="item.selects" class="mode" src="@/assets/images/select.png" alt="" />
                </div>
                <span style="display: block; text-align: center" class="text">{{
                    item.title
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="ifselect" @click="ifselect = false" class="justify-center button">
      <span>选</span>
      <span class="text_9">择</span>
    </div>
    <div v-if="!ifselect" class="flex-row justify-between">
      <div @click="sumit" class="justify-center button" style="width: 40%; margin-left: 0">
        <span>轮</span>
        <span class="text_9">播</span>
      </div>
      <div @click="creatCode" class="justify-center button" style="width: 40%; margin-left: 0">
        手机微信扫码下载图片
      </div>
    </div>
  </div>
</template>

<script>
import code from "./code.vue";
export default {
  props: ["datas"],
  data() {
    return {
      dialogVisible: false,
      ifselect: true,
      lists: [],
      show: false,
      list: [
        { selects: false },
        { selects: false },
        { selects: false },
        { selects: false },
        { selects: false },
        { selects: false },
      ],
    };
  },
  mounted() {
    this.$bus.$on("close", () => {
      this.ifselect = true;
      this.getData();
    });
    this.getData();
  },
  components: {},
  methods: {
    creatCode() {
      var arr = [];
      this.list.forEach((t, i) => {
        t.forEach((ts) => {
          if (ts.selects) {
            arr.push(ts.img);
          }
        });
      });
      this.list.forEach((t, i) => {
        t.forEach((ts) => {
          ts.selects=false;
        });
      });

      console.log(this.list)
      this.ifselect = true;
      this.$modal.open(code, {
        props: {
          datas: arr,
        },
      });
    },
    async getData(sum = 6) {
      let arr = [];
      let { data } = await this.service.post(
        "/index.php/index/goods/bannerlist",
        {
          type: 4,
          page: 1,
          pagesize: 100,
          llqsjs: localStorage.getItem("browserId"),
        }
      );
      data.forEach((t) => {
        t.selects = false;
      });
      for (var i = 0; i < data.length; i += sum) {
        arr.push(data.slice(i, i + sum));
      }
      this.list = arr;
      await this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper", {
          //autoplay: true, //可选选项，自动滑动
          slidesPerView: 1,
          //loop: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
      // console.log(arr);
    },
    sumit() {
      this.list.forEach((t, i) => {
        t.forEach((ts) => {
          if (ts.selects) {
            this.lists.push(ts.img);
          }
        });
      });

      this.$bus.$emit("show", this.lists);
      this.ifselect = true;
      // console.log(this.lists);
    },
    selects(i, is) {
      if (this.ifselect) {
        this.$bus.$emit("show", [this.list[i][is].img]);
      } else {
        this.$set(this.list[i][is], "selects", !this.list[i][is].selects);
      }
    },
  },
};
</script>

<style scoped lang="css">
.carimg .swiper-slide {
  padding: 0 100px;
}

.carimg .swiper-button-next {
  right: 10px;
  transform: translateY(-80px);
  background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089420952833555.png) !important;
  background-size: 100% 100% !important;
  width: 64px !important;
  height: 64px !important;
}

.carimg .swiper-button-next::after {
  content: "";
}

.carimg .swiper-button-prev {
  transform: translateY(-80px);
  left: 10px;
  background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089421562351849.png) !important;
  background-size: 100% 100% !important;
  width: 64px !important;
  height: 64px !important;
}

.carimg .swiper-button-prev::after {
  content: "";
}

.equal-division-item {
  flex-wrap: wrap;
  width: 100%;
}

.equal-division-item .m30 {
  margin: 0 30px 30px 0;
}

.m30>div {
  position: relative;
}

.equal-division-item .m30:nth-child(3n) {
  margin-right: 0;
}

.carimg {
  width: 71%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.equal-division-item>div {
  position: relative;
  width: 180px;
}

.carimg .swiper-slide {
  width: 100% !important;
  padding: 0 5px;
}

.carimg .swiper {
  width: 100% !important;
}

.mode {
  position: absolute;

  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.equal-division-item {}

.image {
  width: 100%;
}

.text {
  margin-top: 20px;
  color: rgb(255, 255, 255);
  font-size: 27px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
}

.rows {
  margin-top: 33px;
}

.text_2 {
  margin-top: 17px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 12px;
  white-space: nowrap;
}

.button {
  margin: 24px 22px 0 31px;
  padding: 19px 0 17px;
  color: rgb(255, 255, 255);
  font-size: 19px;
  font-weight: normal;
  line-height: 17px;
  white-space: nowrap;
  border-radius: 13px;
  filter: drop-shadow(3.34px 3.34px 3.34px rgba(35, 25, 57, 0.2));
  backdrop-filter: blur(40px);
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089436140375222.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_9 {
  margin-left: 19px;
}
</style>
