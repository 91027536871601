<template>
  <div id="app">
    <transition :name="transitionName">
      <!-- <keep-alive>

       </keep-alive> -->
      <router-view />
    </transition>
  </div>
</template>
<script>
import Fingerprint2 from "fingerprintjs2"; // 引入fingerprintjs2
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      transitionName: "slide-left",
    };
  },
  watch: {
    $route(to, from) {
      console.log(to.name, from.name);
      this.transitionName =
        to.name == "index" || (to.name == "chexingtansuo" && from.name == "car")
          ? "slide-right"
          : "slide-left";
    },
  },
  async created() {
  
       if(window.location.href.includes('f23ac4f790bf7e752c3794c86dac45d4')){
        localStorage.setItem("browserId", "f23ac4f790bf7e752c3794c86dac45d4");
        this.$store.commit('setCity','苏州')
        return
       }  
        if(window.location.href.includes('8a4e96c5a8d13ecb431a34074c279333')){
        localStorage.setItem("browserId", "8a4e96c5a8d13ecb431a34074c279333");
        this.$store.commit('setCity','郑州')
        return
       } 
        if(window.location.href.includes('5bdeb3d81e6c158a24018c485dbf5257')){
        localStorage.setItem("browserId", "5bdeb3d81e6c158a24018c485dbf5257");
        this.$store.commit('setCity','成都')
        return
       } 
        if(window.location.href.includes('4c1ae600eae18efd67c64fd964d373a4')){
         
        localStorage.setItem("browserId", "4c1ae600eae18efd67c64fd964d373a4");
        this.$store.commit('setCity','西安')
        return
       }                             
    // 您不应在页面加载时或加载后直接运行指纹。 而是使用setTimeout或requestIdleCallback将其延迟几毫秒，以确保指纹一致。
    // if (localStorage.getItem("browserId")) {
    //   this.sendip(localStorage.getItem("browserId"));
    // } else {
    //   if (window.requestIdleCallback) {
    //     requestIdleCallback(() => {
    //       this.createFingerprint();
    //     });
    //   } else {
    //     setTimeout(() => {
    //       this.createFingerprint();
    //     }, 500);
    //   }
    // }
   // 原生方式  -   google会报跨域
//    console.log("123");
// window.onload = function () {
//     var url = "file:///C:/lixinghui/weixintuiwenxiaoguo/dist/api.json";
//     var request = new XMLHttpRequest();
//     request.open("get", url); // 设置请求方法与路径
//     request.send(null);// 读取本地，就不发送数据到服务器
//     request.onload = function () { // XHR对象获取到返回信息后执行
//         if (request.status == 200) { // 返回状态为200，即为数据获取成功
//              console.log("123456");
//             console.log(request.responseText);
//         }
//     }
// }
    
  },

  mounted() {
    setInterval(() => {
      this.sendip();
    }, 1000 * 60 * 5);
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    // 创建浏览器指纹
    createFingerprint() {
      // 浏览器指纹
      const fingerprint = Fingerprint2.get((components) => {
        // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
        const values = components.map((component) => component.value); // 配置的值的数组
        const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
        //  console.log(components);
        //  console.log(values);
        // console.log(murmur);
        localStorage.setItem("browserId", murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
        this.sendip(murmur);
      });
    },
    async sendip() {
      // console.log(returnCitySN)
      if (localStorage.getItem("browserId")) {
        var llqsjs = localStorage.getItem("browserId");
        await this.service.post("/index.php/index/Goods/ismobileff", {
          ip: returnCitySN["cip"],
          times: Math.floor(new Date().getTime() / 1000),
          llqsjs,
        });
      } else {
        setTimeout(() => {
          this.sendip();
        }, 1000);
      }
    },
    async getBqList() {
      let { data } = await this.service.post("/index.php/index/Admins/bqlist");
      this.setBqList(data);
    },
    ...mapMutations(["setUser", "setBqList"]),
    openWX() {
      this.$modal.open(vcode /* 或'componentName' */, {
        props: {
          message: "here is a message",
          onOk: () => {
            console.log("ok");
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
*::-webkit-scrollbar {
  display: none;
}
.jz {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 1);
  z-index: 10;
}
* {
  margin: 0;
  padding: 0;
}
.page {
  min-height: 100vh;
}
// .slide-right-enter-active,
// .slide-right-leave-active,
// .slide-left-enter-active,
// .slide-left-leave-active {
//   position: absolute; // 需要注意的地方
//   width: 100%; // 需要注意的地方，否则会出现页面渲染卡顿现象
//   will-change: transform;
//   transition: all .8s ease-out;
// }

// .slide-right-enter {
//   opacity: 0;
//   transform:scale(2) ;
// }

// .slide-right-leave-active {
//   opacity: 0;
//   transform: scale(1);
// }

// .slide-left-enter {
//   opacity: .5;
//   transform: scale(.1);
// }

/* 左侧离场，右侧入场 动画 */
.slide-left-leave {
  transform: translate(0, 0);
}

.slide-left-leave-active,
.slide-left-enter-active {
  transition: all 0.7s;
  /* 为了保证组件切换动画时，两个组件重叠显示，在动画持续时间将做动画的组件根元素设置为绝对行为 */
  position: absolute;
}

.slide-left-leave-to {
  transform: translate(-100%, 0);
}

.slide-left-enter {
  transform: translate(100%, 0);
}

.slide-left-enter-to {
  transform: translate(0, 0);
}

/* 右侧离场，左侧入场 动画 */
.slide-right-leave {
  transform: translate(0, 0);
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: all 0.7s;
  position: absolute;
}

.slide-right-leave-to {
  transform: translate(100%, 0);
}

.slide-right-enter {
  transform: translate(-100%, 0);
}

.slide-right-enter-to {
  transform: translate(0, 0);
}
/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/
.a {
  cursor: pointer;
}
input,
textarea {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
}
.nomal-block {
  width: 1197px;
  margin: 0 auto;
}


// html {
//   font-size: 10PX !important  ;
// }
// @media screen and (max-width: 1500px) {
//   html {
//     font-size: 8PX !important;
//   }
// }
// @media screen and (max-width: 1200px) {
//   html {
//     font-size: 7PX !important;
//   }
// }
// @media screen and (max-width: 1100px) {
//   html {
//     font-size: 6PX !important;
//   }
// }
// @media screen and (max-width: 1000px) {
//   html {
//     font-size: 1.3333333333vw !important;
//   }
// }
// @media screen and (max-width: 850px) {
//   html {
//     font-size: 1.3333333333vw !important;
//   }
// }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Microsoft Yahei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  flex-shrink: 0;
}

#app {
  min-height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.items-start {
  display: flex;
  align-items: flex-start;
}

.items-center {
  display: flex;
  align-items: center;
}

.items-end {
  display: flex;
  align-items: flex-end;
}
</style>
