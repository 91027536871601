<template>
  <div>
    <img v-show="show" class="tip" src="@/assets/images/tips.png" alt="">
  <div class="flex-col page index" @click="dos">
    <div class="swiper swipers">
      <div class="swiper-wrapper">
        <div
          v-if="lists && lists.length > 0"
          v-for="t in lists"
          class="swiper-slide"
        >
          <img
            style="width: 100vw"
            :src="$img(t)"
            alt=""
          />
        </div>
        <!-- <div v-if="!lists" v-for="t in 4" class="swiper-slide">
          <img
            style="width: 100vw"
            :src="require(`@/assets/images/${type?'iox':'like'}/${t}.png`)"
            alt=""
          />
        </div> -->
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    lists: {
      default: null,
    },
  },
  data() {
    return {
      show:true,
      type:null,
      list:[]
    };
  },
  mounted() {
    setTimeout(()=>{
      this.show=false;
    },3000)
    if(this.$route.query.id){
       this.type=this.$route.query.id;
       this.getData()
    }
   
    this.$nextTick(() => {
      var mySwiper = new Swiper(".swipers", {
        autoplay: {
        delay: 10000,
        }, //可选选项，自动滑动
        slidesPerView: 1,
        loop: true,
      });
    });
  },
  watch: {

  },
  methods: {
    async getData(){
     let {data} =   await this.service.post("/index.php/index/index/goodslist")
     this.lists=data[this.type].content;
      
      this.$nextTick(() => {
        var mySwiper = new Swiper(".swipers", {
          autoplay: {
          delay: 10000,
                  }, //可选选项，自动滑动

          slidesPerView: 1,
          loop: true,
        });
      });
    },
    dos() {
      
      if (!this.type) {
        this.$emit("close");
        this.$bus.$emit("close")
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="less">
.swiper {
  width: 100vw;
  overflow: hidden;
}
.tip{
  position: fixed;
  width: 70%;

  left: 0;
  right: 0;
  margin: 30px auto;
  z-index: 10;
}
</style>
