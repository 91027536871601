import axios from "axios";
import { api, lp, getRandom } from "./config.js";
import sha1 from "js-sha1";
import md5 from "js-md5";
// //引入进度条
// import Nprogress from "nprogress";
// //引入进度条样式
// import 'nprogress/nprogress.css'

//利用axios对象的方法create，去创建一个axios实例
const service = axios.create({
  //基础路径
  baseURL: api,
  //请求超时时间   （5s内没响应就失败了）
  timeout: 999999999999999999999999999999,
});

//请求拦截器
//axios实例的拦截器的request请求使用配置对象，返回配置对象
service.interceptors.request.use(
  (config) => {
    //进度条开始
    //加密顺序：12随机数  + 时间戳 + 口令
    let rannumbers = getRandom() + "";
    let timestamps = new Date().getTime() + "";
    let signs = sha1(rannumbers+timestamps+lp);
    // console.log(config.url.includes("uploadVideo"));
    // if(config.url.includes("upload")){
    //   config.url="http://spdsupload.cttqvi.com/" + config.url;
    // }
    if (config.data) {
      
      if (Object.prototype.toString.call(config.data) != "[object FormData]") {
        // 不是formdata类型
        config.data.rannumbers = rannumbers;
        config.data.timestamps = timestamps;
        config.data.signs = signs;
      } else {
        config.data.append("rannumbers", rannumbers);
        config.data.append("timestamps", timestamps);
        config.data.append("signs", signs);
      }
    } else {
      config.data = {};
      config.data.rannumbers = rannumbers;
      config.data.timestamps = timestamps;
      config.data.signs = signs;
    }
    //console.log(config);
    //请求成功的返回配置对象
    return config;
  },
  (error) => {
    //请求失败的返回，后面的then或者catch回调随便写不写
    return Promise.reject(error);
  }
);

//响应拦截器
service.interceptors.response.use(
  (response) => {
    //进度条结束

    //响应成功的返回
    return response.data;
  },
  (error) => {
    //响应失败的返回
    return Promise.reject(error);
  }
);

//导出axios实例
export default service;
