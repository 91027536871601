import Vue from 'vue';
import VueRouter from 'vue-router';

import Chexingtansuo from '@/pages/chexingtansuo.vue';
import Index from '@/pages/index.vue';

import car from '@/pages/carinfos.vue';
import videos from '@/pages/video.vue';
import zmt from '@/pages/zmt.vue';

import demo from '@/pages/demo.vue';
import demos from '@/pages/demos.vue';
import zdyw from '@/pages/zdyw.vue';
import fig from '@/pages/fig.vue';
import rank from '@/pages/rank.vue';
import ranks from '@/pages/newrank.vue';
import down from '@/pages/down.vue';
import cy from '@/pages/cy/index.vue';
import cynb from '@/pages/cy/indexnb.vue';
import cysjz from '@/pages/cy/indexsjz.vue';
Vue.use(VueRouter);
var newindex;
if(process.env.NODE_ENV=='stage'){
  newindex= [{
    path: '/',
    name: 'demo',
    component: demo,
  }]
}else if(process.env.NODE_ENV=='cy'){
  newindex= [{
    path: '/',
    name: 'cy',
    component: cy,
  }]
}else if(process.env.NODE_ENV=='cysjz'){
  newindex= [{
    path: '/',
    name: 'cy',
    component: cysjz,
  }]
}
else if(process.env.NODE_ENV=='cynb'){
  newindex= [{
    path: '/',
    name: 'cynb',
    component: cynb,
  }]
}
else{
  newindex=[{
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/cynb',
    name: 'cynb',
    component: cynb,
  },
  {
    path: '/about',
    name: 'about',
    component:() => import('@/pages/about1.vue') ,
  },
  {
    path: '/about2',
    name: 'about2',
    component:() => import('@/pages/about2.vue') ,
  },
]
}

// const routes = [{
//   path: '/',
//   name: 'cynb',
//   component: cynb,
// }]
const routes = [


  ...newindex,

  {
    path: '/down',
    name: 'down',
    component: down,
  },
  {
    path: '/ranks',
    name: 'ranks',
    component: ranks,
  },
  
  {
    path: '/fig',
    name: 'fig',
    component: fig,
  },
  {
    path: '/zdyw',
    name: 'zdyw',
    component: zdyw,
  },
  {
    path: '/chexingtansuo',
    name: 'chexingtansuo',
    component: Chexingtansuo,
  },
  {
    path: '/demo',
    name: 'demo',
    component: demo,
  },
  {
    path: '/demos',
    name: 'demos',
    component: demos,
  },
  {
    path: '/car',
    name: 'car',
    component: car,
  },
 
  {
    path: '/zmt',
    name: 'zmt',
    component: zmt,
  },
  {
    path: '/videos',
    name: 'videos',
    component: videos,
  },
  {
    path: '/rank',
    name: 'rank',
    component: rank,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
