<template>
  <div>
    <v-touch @swipeup="qihuan" @swipedown="qihuan">
      <div class="hub-slider">
        <div class="hub-slider-slides">
          <ul>
            <li  v-for="(t, i) in list" :data-index="i" @click="dianjis(i)">

              <img
                v-show="index != i"
                class="smallbg"

                :src="$img(t.img)"
                alt=""
              />
              <div v-show="index == i" class="flex-col section_1">
                <img
                  :src="$img(t.bigimg)"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>

        <!-- <div class="hub-slider-controls">
          <button class="hub-slider-arrow hub-slider-arrow_next">↑</button>
          <button class="hub-slider-arrow hub-slider-arrow_prev">↓</button>
        </div> -->
      </div>
    </v-touch>
  </div>
</template>

<script>
import _ from "lodash"
export default {
  name: "WorkspaceJsonDemo",

  data() {
    return {
      index: 0,
      list:[]
    };
  },

  mounted() {
    this.getData()

    // setTimeout(() => {
    //   movement("prev");
    // }, 1000);
  },

  methods: {
    async dianjis(i){
      await this.service.post("/index.php/index/goods/vhistoryadd",{ip:returnCitySN["cip"],classify:returnCitySN["cname"],pid:this.list[i].id,name:this.list[i].title,alink:"",llqsjs:localStorage.getItem('browserId')})
      this.navPath('/car?id='+i)
    },
    async getData(){
     let {data} =   await this.service.post("/index.php/index/index/goodslist")
     this.list=data;
     this.init()
    },
    init(){
      this.$nextTick(()=>{
        var that = this;
        var hubSlider = $(".hub-slider-slides ul").hubSlider({
          selector: $("li"),
          scale:1,
          scaleStep:0.2,
          change: (data) => {
            console.log(data);
            that.index = data;
          },
          button: {
            next: $(".hub-slider-arrow_next"),
            prev: $(".hub-slider-arrow_prev"),
          },
        });
      })
    },
  //  qihuan: _.throttle(
  //     function () {
  //       movement("prev");
  //     },
  //     1000,
  //     {
  //       leading: true, //指定调用在节流开始前
  //       trailing: false, //指定调用在节流结束后,
  //     }
  //   ),
    qihuan(){
      movement("next");
    }
  },
};
</script>

<style scoped>

.smallbg {

width: 100%;
  display: block;
  margin: 0 auto;
}
.hub-slider {
  position: relative;
  
}

.hub-slider ul {
  list-style: none;
}

.hub-slider ul li {
  width: 100%;
      backdrop-filter: blur(40px);
  position: absolute;
  left: 0;
  top: 0;
}

.hub-slider-controls {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}

.hub-slider-arrow {
  width: 40px;
  height: 40px;
  border: none;
  background: #f00;
  color: #fff;
  font-weight: bold;
}
</style>
