<template>
  <i>{{ `${hr}:${min}:${sec}` }}</i>
</template>

<script>
export default {
  name: 'countdown',
  props: {
    start: {
      type: String,
      default: '2021-12-01 00:00:00'
    },
    duration: {
      type: Number,
      default: 72 * 60 * 60 * 1000
    }
  },
  mounted () {
    this.countdown()
  },
  destroyed () {
    clearTimeout(this.timer)
  },
  methods: {
    countdown () {
      if(this.duration==0){
        this.$emit('res');
      }
      const end = Date.parse(new Date(this.start)) + this.duration
      const now = Date.parse(new Date())
      const msec = end - now
      if (msec <= 0) {
        this.day = 0
        this.hr = 0
        this.min = 0
        this.sec = 0
        clearTimeout(this.timer)
        return
      }
      let day = parseInt(msec / 1000 / 60 / 60 / 24)
      let hr = parseInt(msec / 1000 / 60 / 60 % 24)
      let min = parseInt(msec / 1000 / 60 % 60)
      let sec = parseInt(msec / 1000 % 60)
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      const that = this
      this.timer = setTimeout(function () {
        that.countdown()
      }, 1000)
    }
  },
  data () {
    return {
      timer: null,
      day: '',
      hr: '',
      min: '',
      sec: ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

