<template>
  <div class="flex-col page video">
    <vheader></vheader>
    <div class="flex-col line-tabs">
      <div class="flex-col group_1">
        <div class="justify-between group_2">
          <div class="flex-row justify-around" style="width: 100%">
            <span @click="tab = 0" :class="tab == 0 ? 'text' : 'text_3'"
              >车型视频</span
            >
            <span @click="tab = 1" :class="tab == 1 ? 'text' : 'text_3'" class="text_1 text_2">换电视频</span>
            <span
              @click="tab = 2"
              :class="tab == 2 ? 'text' : 'text_3'"
              class="text_1"
              >车型海报</span
            >
            <span  @click="zuixin" class="text_1 text_4">最新活动</span>
          </div>
        </div>
        <div class="section_1"></div>
      </div>
      <div class="flex-col">
        <div class="flex-col section_2">
          <div v-if="tab == 0||tab == 1" class="swiper">
            <div v-if="list.length > 0" class="swiper-wrapper">
              <div v-for="t in list" class="swiper-slide">
                <div class="flex-row equal-division">
                  <div
                    @click="bofangs(t[0].videos)"
                    class="flex-col equal-division-item"
                  >
                    <div class="flex-col items-center image-wrapper">
                      <img class="videocar" :src="$img(t[0].img,t[0].images)" alt="" />
                      <img
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089417790194292.png"
                        class="image_8"
                      />
                    </div>
                    <span class="text_7">{{ t[0].title }}</span>
                  </div>
                  <div
                    v-if="t[1]"
                    @click="bofangs(t[1].videos)"
                    class="flex-col equal-division-item"
                  >
                    <div class="flex-col items-center image-wrapper">
                      <img class="videocar" :src="$img(t[1].img,t[1].images)" alt="" />
                      <img
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089417790194292.png"
                        class="image_8"

                      />
                    </div>
                    <span class="text_7">{{ t[1].title }}</span>
                  </div>
                </div>
                <div class="flex-row equal-division">
                  <div
                    v-if="t[2]"
                    @click="bofangs(t[2].videos,t[2].images)"
                    class="flex-col equal-division-item"
                  >
                    <div class="flex-col items-center image-wrapper">
                      <img class="videocar" :src="$img(t[2].img)" alt="" />
                      <img
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089417790194292.png"
                        class="image_8"
                      />
                    </div>
                    <span class="text_7">{{ t[2].title }}</span>
                  </div>
                  <div

                    @click="bofangs(t[3].videos,t[3].images)"
                    class="flex-col equal-division-item"
                  >
                    <div v-if="t[3]" class="flex-col items-center image-wrapper">
                      <img class="videocar" :src="$img(t[3].img)" alt="" />
                      <img
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089417790194292.png"
                        class="image_8"
                      />
                    </div>
                    <span v-if="t[3]" class="text_7">{{ t[3].title }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev"></div>
            <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <div class="swiper-button-next"></div>
            <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
          </div>
          <videoimg  v-show="tab == 2"></videoimg>
        </div>
        <div class="h100"></div>
        <vfooter></vfooter>
      </div>
    </div>
    <div class="wrap" v-if="bofang">
      <videoview :videotips="videotips" :videosrc="videosrc" @close="bofang = false"></videoview>
    </div>
    <div class="wrap" v-if="shows">
      <carinfo @close="shows = false" :lists="lists"></carinfo>
    </div>
  </div>
</template>

<script>
import carinfo from "./carinfos.vue";
import videoview from "./videoview.vue";
import videoimg from "./videoimg.vue";
export default {
  data() {
    return {
      videotips:null,
      bofang: false,
      videosrc:"",
      tab: 0,
      list: [],
      lists: [],
      shows: false,
    };
  },
  components: { videoview, videoimg, carinfo },
  async mounted() {
    this.$bus.$on("show", (data) => {
      this.lists = data;
      this.shows = true;
    });

    this.getData();
  },
  watch:{
    tab(){
      this.getData()
    }
  },
  methods: {
    async zuixin(){
      let {data} = await this.service.post("/index.php/index/goods/bannerlist",{
          type:5,
          page: 1,
          pagesize: 100,
          llqsjs:localStorage.getItem("browserId")
        })
        console.log(data)
      
        this.lists= data.filter(t=>t.llqsjsshow==1).map(t=>t.img);
        this.shows=true;
    },
    bofangs(src,url){
       this.videosrc=src;
       this.videotips=url;
       this.bofang=true;
    },
    async getData(sum = 4) {
      let arr = [];
      let { data } = await this.service.post(
        "/index.php/index/goods/bannerlist",
        {
          type:this.tab+2,
          page: 1,
          pagesize: 100,
          llqsjs:localStorage.getItem("browserId")
        }
      );
      for (var i = 0; i < data.length; i += sum) {
        arr.push(data.slice(i, i + sum));
      }
      this.list = arr;
      await this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper", {
          //autoplay: true, //可选选项，自动滑动
          slidesPerView: 1,
          // loop: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });

    },
  },
};
</script>
<style scoped lang="css">
.video .swiper-slide {
  padding: 0 100px;
}
.video .swiper-button-next {
  right: -0px;
  background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089420952833555.png) !important;
  background-size: 100% 100% !important;
  width: 64px !important;
  height: 64px !important;
}
.video .swiper-button-next::after {
  content: "";
}

.video .swiper-button-prev {
  left: -0px;
  background-image: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089421562351849.png) !important;
  background-size: 100% 100% !important;
  width: 64px !important;
  height: 64px !important;
}
.video .swiper-button-prev::after {
  content: "";
}
</style>
<style scoped lang="css">
.swiper {
  width: 100%;
  overflow: hidden;
}
.h100 {
  height: 100px;
}
.image_1 {
  width: 38px;
  height: 26px;
}
.text_1 {
  opacity: 0.7;
}
.center-text-wrapper {
  width: 121px;
}
.image_6 {
  width: 67px;
  height: 67px;
}
.image_8 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 67px;
  height: 67px;
}
.page {
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16524901229737534885.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  height: 100%;
}
.line-tabs {
  margin-top: 39px;
}
.image {
  width: 167px;
  height: 28px;
}
.image_2 {
  margin-left: 14px;
}
.image_3 {
  margin-left: 8px;
  width: 38px;
  height: 25px;
}
.image_4 {
  margin-left: 8px;
}
.image_5 {
  margin-left: 5px;
}
.group_1 {
  padding: 0 77px 20px 95px;
}
.group_2 {
  padding: 20px 0 14px;
}
.text {
  border-bottom: 5px solid rgb(0, 71, 255);
  padding-bottom: 10px;
}
.boractive {
}
.section_2 {
  margin-left: 26px;
  margin-right: 26px;
  padding: 70px 14px 73px 14px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 15px;
  white-space: nowrap;
  border-radius: 27px;
  backdrop-filter: blur(40px);
  box-shadow: 0px 2.66px 2.66px rgba(0, 0, 0, 0.25);
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089414715059121.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
 
}
.section_3 {
  margin-top: 237px;
  padding: 6px 77px 5px 110px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
}
.text_3 {
  margin-top: 6px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 300;
  line-height: 19px;
  white-space: nowrap;
}
.text_4 {
  margin-top: 6px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
}
.equal-division {
  margin-top: -12px;
  align-self: center;
  position: relative;
}
.text-wrapper {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  white-space: nowrap;
}
.view {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  white-space: nowrap;
}
.view_1 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  line-height: 19px;
  white-space: nowrap;
}
.text {
  color: rgb(255, 255, 255);
  font-size: 27px;
  font-weight: 600;
  line-height: 25px;
  white-space: nowrap;
}
.text_2 {
  margin-top: 6px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 300;
  line-height: 19px;
  white-space: nowrap;
}
.group_6 {
  flex: 1 1 290px;
}
.group_8 {
  margin-left: 60px;
  flex: 1 1 290px;
}
.equal-division-item {
  padding: 30px;
  flex: 1 1 260px;
  width: 260px;
}
.equal-division-item_1 {
  padding: 30px;
  flex: 1 1 260px;
  width: 260px;
}
.image_7 {
  margin-top: 261px;
}
.group_7 {
  margin-left: 23px;
  flex: 1 1 auto;
}
.group_9 {
  flex: 1 1 auto;
}
.image_11 {
  margin-left: 23px;
  margin-top: 261px;
}
.image-wrapper_2 {
  padding: 100px 0 100px;

  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text_7 {
  margin-top: 18px;
  align-self: center;
}
.image-wrapper_3 {
  padding: 100px 0 100px;
  filter: drop-shadow(3.34px 3.34px 5px rgba(0, 0, 0, 0.4));
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089414733774101.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text_8 {
  margin-top: 14px;
  align-self: center;
}
.image-wrapper {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.videocar {
  width: 100%;
}
.text_5 {
  margin-top: 18px;
  align-self: center;
}
.image-wrapper_1 {
  padding: 100px 0 100px;
  filter: drop-shadow(3.34px 3.34px 5px rgba(0, 0, 0, 0.4));
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16525089414734931234.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text_6 {
  margin-top: 18px;
  align-self: center;
}
</style>
