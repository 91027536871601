import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import vheader from "./components/header.vue";
import vfooter from "./components/footer.vue";
import ModalWizard from "vue-modal-wizard";
import VueCookies from "vue-cookies";
import service from "./api";
import ElementUI from "element-ui";
import { api } from "./config";

import validate from "@/assets/js/ys-validate.js";
import "element-ui/lib/theme-chalk/index.css";
import Vuex from "vuex";
import store from "./store";
import './rem'




import animated from "animate.css"
Vue.use(animated)


import 'aos/dist/aos.css'
import AOS from 'aos/dist/aos.js'

import vueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(vueAwesomeSwiper);


Vue.directive('title', {//单个修改标题
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

import 'vue-animate-fullpage/dist/animate.css'
import VueAnimateFullpage from 'vue-animate-fullpage/dist/index'
Vue.use(VueAnimateFullpage)
Vue.prototype.$fullscreen = VueAnimateFullpage; //全局挂载，方便手动跳转
Vue.prototype.$name = (phones)=>{
	return `骑士(${phones.substr(7,11)})`
}
import VueTouch from "vue-touch";
Vue.use(VueTouch, {name: "v-touch"});

import vueTouchs from 'kim-vue-touch'
Vue.use(vueTouchs)
Vue.prototype.$validate = validate;
Vue.use(ElementUI);
Vue.use(VueCookies);
Vue.use(ModalWizard);
Vue.config.productionTip = false;
Vue.component("vfooter", vfooter);
Vue.component("vheader", vheader);

Vue.prototype.nav = (name) => {
  router.push({ name });
};
Vue.prototype.navPath = (path) => {
  router.push({ path });
};
Vue.prototype.service = service;
Vue.prototype.$img = (url) => {
  return api + url;
};
Vue.prototype.$video = (url) => {
  return 'http://gyyjggadmin.hansn.cn/uploads/avatar/' + url;
};
//视频状态status  0 审核中         1正常显示     2隐藏
Vue.prototype.status = {
  0: "审核中",
  1: "正常",
  2: "隐藏",
};
Vue.prototype.zhBq = (arr) => {
  if (!arr) {
    return "";
  }
  console.log(arr)
  return arr.map((t) => {
    return store.state.bqList.find((ts) => ts.id == t).title;
  });
};

Vue.use(Vuex);
export default  new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
  	Vue.prototype.$bus=this;
  }
}).$mount("#app");
