<template>
  <div class="flex-col page index">
    <div style="min-height: 100vh" :class="'a' + index">
      <vheader class="group_1" />
      <div class="wraps">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img
                 class="s"
                src="@/assets/images/index/1.png"
                alt=""
              />
               <img
                 class="ss"
                src="@/assets/images/index/1s.png"
                alt=""
              />
            </div>
            <div class="swiper-slide">
              <img  class="s" src="@/assets/images/index/2.png" alt="" />
               <img  class="ss" src="@/assets/images/index/2s.png" alt="" />
            </div>
            <div class="swiper-slide">
            <div class="swiper-slides">
            <img
               class="s"
              src="@/assets/images/index/3.gif"
              alt=""
            />
             <img
               class="ss"
              src="@/assets/images/index/3.gif"
              alt=""
            />
             <div class="swiper-slide-title">
               <p>自媒体</p>

             </div>
            </div>
            </div>
            <div class="swiper-slide">
               <div class="swiper-slides">
            <img
               class="s"
              src="@/assets/images/index/about.gif"
              alt=""
            />
             <img
               class="ss"
              src="@/assets/images/index/about.gif"
              alt=""
            />
             <div class="swiper-slide-title">
               <p>关于光阳</p>

             </div>
            </div>
            </div>
            
            <div class="swiper-slide">
              <img  class="s" src="@/assets/images/index/5.png" alt="" />
               <img  class="ss" src="@/assets/images/index/5s.png" alt="" />
            </div>
            <div class="swiper-slide">
            <div class="swiper-slides">
            <img
               class="s"
              src="@/assets/images/index/6.png"
              alt=""
            />
             <img
               class="ss"
              src="@/assets/images/index/6.png"
              alt=""
            />
             <div class="swiper-slide-title">
               <p>试驾排行榜</p>

             </div>
            </div>
            </div>
          </div>
           <div class="swiper-pagination"></div>
        </div>

      </div>

      <vfooter class="section_2" />
    </div>
  </div>
</template>

<script>

import _ from "lodash";
export default {
  data() {
    return {
      index: 1,
      url:{
        1:"/chexingtansuo",
        2:"/zdyw",
        3:"/zmt",
        4:"/demos",
        5:"/videos",
         6:"/rank",
      }
    };
  },
  async mounted() {
    var that = this;
    await this.$nextTick(() => {
      var mySwiper = new Swiper(".swiper", {
        //autoplay: true, //可选选项，自动滑动
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          slideChange: function () {
            that.index = this.realIndex + 1;
          },
          click: function () {
            // 这里有坑
            // 需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // console.log(this); // -> Swiper
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。            const realIndex = this.realIndex;
            that.navPath(that.url[this.realIndex+1]);
          }
        },
      });
    });
  },
  methods: {

  },
};
</script>
<style lang="css">
.swiper-slide{

}
.swiper-slides{
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.swiper-slide-title{
  padding: 20px;
  background-image: url(../assets/images/index/indexsbg.png);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(20px);
}
.swiper-slide-title p:first-child{
  font-size: 26px;
}
.swiper-slide .s{
  display: none;
}
.swiper-slide .ss{
  display: block;
}
.swiper-slide.swiper-slide-active .s{
    display: block;
  }
.swiper-slide.swiper-slide-active .ss{
    display: none;
  }
.wraps {
  display: flex;
  align-items: center;
  height: calc(100vh - 200px);
  justify-content: center;
  flex-wrap: wrap;
}
.a1 {
  background: url(../assets/images/index/1b.png);
  background-size: 100% 100%;
}
.a2 {
  background: url(../assets/images/index/2b.png);
  background-size: 100% 100%;
}
.a3 {
  background: url(../assets/images/index/zmt.jpg);background-size: 100% 100%;
}
.a4 {
  background: url(../assets/images/index/4b.png);background-size: 100% 100%;
}
.a5 {
  background: url(../assets/images/index/5b.png);background-size: 100% 100%;
}
.a6 {
  background: url(../assets/images/index/6b.png);background-size: 100% 100%;
}
 .index .swiper-pagination {
    position: relative !important;
     transform: translateY(-15%);
    bottom: 100px !important;
  }
 .index .swiper-pagination-bullet {
    background-color: white;
  }

</style>
<style scoped lang="css">
.swiper {
  margin-bottom: 290px;
}
.swiper-slide {
  color: aliceblue;
}
/* 轮播图 */
.swiper {
  width: 100vw;
  height: 55vh;
  transform: scale(1.35);
}
.swiper-slide {
  /* width: 50% !important; */
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(1);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1.5);
  z-index: 9;
}

.swiper-slide img {
  width: 100%;
}

.swiper-button-prev {
  left: 30px;
  right: auto;
}

.swiper-button-prev:after {
  content: "none";
}

.swiper-button-next {
  right: 30px;
  left: auto;
}

.swiper-button-next:after {
  content: "none";
}

.group_1 {
  position: relative;
}
.section_2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.page {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  position: relative;
  background: url(https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16524901229737534885.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.section {
  padding-top: 547px;

  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/627eff8310b58700114f4014/16524901229733289517.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 448px;
}

.section_1 {
  padding: 28px 0 31px;
  background-color: rgba(0, 101, 255, 0.15);
  border-radius: 0px 0px 27px 27px;
  backdrop-filter: blur(40px);
  width: 448px;
}
.text {
  color: rgb(255, 255, 255);
  font-size: 33px;
  line-height: 31px;
  white-space: nowrap;
}
.text_1 {
  margin-top: 13px;
  color: rgb(255, 255, 255);
  font-size: 19px;
  font-weight: 300;
  line-height: 17px;
  white-space: nowrap;
}
</style>
